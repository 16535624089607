<template xmlns="element-ui">
  <div class="pro-list2-container">
    <van-radio-group v-model="id" @change="valChange">
      <van-radio :name="item.id" v-for="item in products" :key="item.id">
        <div class="pro-list2">
          {{item.name}}
          <span class="pro-list2-tip" v-if="item.remark1">{{JSON.parse(item.remark1)[0]}}</span>
        </div>
      </van-radio>
    </van-radio-group>
  </div>
</template>
<script>
import {selectAll} from "@/api/product";

export default {
    props:['form','attr','type'],
    data() {
      return {
        products:[],
        id:null
      }
    },
    mounted() {
      this.id = this.form[this.attr]
      selectAll({status:1,type:this.type}).then(res => {
        this.products = res.data;
        this.$forceUpdate()
      })
    },
    methods:{
      valChange(){
        this.form[this.attr] = this.id
      }
    }
	};
</script>

<style lang="scss">

.pro-list2-container{
  .van-radio__label{
    width: 100%;
  }
  .van-radio__label{
    padding: 8px 0;
  }
  .pro-list2{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    &-tip{
      font-size: 12px;
      font-weight: bold;
      color: #ff3b30;
    }
  }
}

</style>
