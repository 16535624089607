// 导入axios
import axios from 'axios'
import md5 from 'js-md5';
import router from '../router'
import {getSign,getSignByTimestamp} from '@/assets/js/EncryptionUtil';
import * as aes from './aes.js';
import {Toast} from "vant";
//1. 创建新的axios实例
const service = axios.create({
	// 公共接口
	baseURL:Config.HttpHost,
	// 超时时间 单位是ms
	timeout: 60 * 1000
})
// 2.请求拦截器
service.interceptors.request.use(config => {
	//如果是post请求 并且没有显示配置请求头时 默认用json格式
	if(config.method=="post"&&!config.headers["Content-Type"]){
		config.headers = {
		  'Content-Type':'application/json'
		}
	}
	//原本的签名方法
	let sign = getSign();
	//请求参数转为json字符串
	let params = JSON.stringify(config.data);
	//参数加密 使用AES加密 加密的key为原来的签名方法生成的签名
	// config.data = params?aes.encrypt(params,sign.sign):null;
	//// 将时间戳放入请求头
	// config.headers["timestamp"] = sign.timestamp;
	// //生成一个最终的签名 将原本的签名和加密后的参数md5形成新签名 并放入请求头
	// config.headers["sign"] = md5(sign.sign+(config.data?config.data:""));
	//如果缓存中有token时 将之加入到header中
	config.headers["v-token"] = localStorage.getItem("v-token");
	config.headers["timestamp"] = sign.timestamp;
	config.headers["sign"] = sign.sign;
	return config
}, error => {
	Promise.reject(error)
})

// 3.响应拦截器
service.interceptors.response.use(response => {
	//参数解密
	// if(response.data&&response.data.data){
	// 	let result = aes.decrypt(response.data.data,getSignByTimestamp(response.data.timestamp));
	// 	try{
	// 		//不能解析成对象 就直接字符串赋值
	// 		response.data.data = JSON.parse(result);
	// 	}catch(err){
	// 		response.data.data = result;
	// 	}
	// }
	//报错了统一处理方法 300以下的状态码是为后台准备的表示成功的状态码
	if(response.data){
		if(response.data.code==200){
			return response.data||response
		}else if(response.data.code!=200){
			let msg = response.data.msg;
			switch (response.data.msg) {
				case '0x00103':
					msg = "短信发生间隔为60秒，请稍后再试";
					break;
			}
			Toast.fail({
				message:msg,
				duration:3500
			})
			throw new Error(msg);
		}
		return;
	}
	Toast.fail({
		message:"网络异常",
		duration:3500
	})
	throw new Error("网络异常");
}, error => {
	if(!error.response){
		Toast.fail("网络错误");
		return;
	}
	switch (error.response.status) {
		case 404:
			error.message = "链接不存在";
			break;
		default:
			error.message = "网络错误";
			break;
	}
	Toast.fail(error.message);
})
//4.导入文件
export default service
