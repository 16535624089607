import request from '@/utils/request.js'

export function selectOne(data) {
  return request({
    url: '/api/pro-rule-page/selectOne',
    method: 'post',
    data
  })
}
export function selectAll(data) {
  return request({
    url: '/api/pro-rule-page/selectAll',
    method: 'post',
    data
  })
}
export function selectAllByUpload(data) {
  return request({
    url: '/api/pro-rule-page/selectAllByUpload',
    method: 'post',
    data
  })
}