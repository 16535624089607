<template>
	<div class="my-content-container" style="width: calc(100% - 24px);height: calc(100vh - 24px);padding: 12px;">
    <div v-if="type == 1">
      <h4>关于我们</h4>
      <div v-html="config.about"/>
    </div>
    <div v-if="type == 2">
      <h4>联系我们</h4>
      <div v-html="config.linkMe"/>
    </div>
    <div v-if="type == 3">
      <h4>安装教程</h4>
      <div v-html="config.tutorial"/>
    </div>
    <div v-if="type == 4"></div>
    <div v-if="type == 5"></div>
	</div>
</template>

<script>
	export default {
		data: function() {
			return {
        type:1,
        config:{
          linkMe:'',
          about:'',
          tutorial:''
        }
			};
		},
    created() {
      this.type = this.$route.query.type
      this.config = JSON.parse(localStorage.getItem("config"))
    }
  };
</script>

<style>
	.my-content-container{
		padding: 12px;
    color: #333;
    font-size: 13px;
    line-height: 30px;
	}
  .van-cell{
    padding: 10px 0;
  }
</style>
