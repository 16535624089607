<template>
  <div class="order-main">
    <div v-if="!user || !(orders && orders.length)">
      <div class="main">
        <from-title title="用户信息"/>
        <van-field readonly v-model="form.carNumber" label="车牌号码" placeholder="点击输入车牌号码" @click="openCarInput"/>
        <van-field type="number" v-model="form.phone" label="手机号码" placeholder="点击输入收货人手机号码"/>
        <div style="display: flex;justify-content: center;align-items: center">
          <div class="btn mt16" @click="getOrder">查询订单</div>
        </div>
      </div>
    </div>
    <div v-else class="order-box" v-for="order of orders">
      <from-title :title="'订单号：'+order.no" :subtitle="'状态：'+(order.status==1?'未付款':order.status==2?
      '待处理':order.status==3?'处理中':order.status==4?'已完成':order.status==5?'退款中':'退款完成')"/>
      <van-card
          num="1"
          :price="order.price?order.price.toFixed(2):0.00"
          desc="数量：1"
          :title="order.proName"
          :thumb="order.type <=2&&order.product?order.product.img:''"
      />
      <div class="order-btns">
        <van-button size="small" @click="toUpload(order)" v-if="order.needUpload && order.status > 1 && order.status < 5" class="but">{{order.attrs&&order.attrs.length ? "修改资料":"上传资料"}}</van-button>
<!--        占位符-->
        <span v-else></span>
        <van-button size="small" @click="pay(order.no)" v-if="order.status === 1" class="but">继续支付</van-button>
        <van-button size="small" @click="getTraces(order)" v-if="hasTraces(order)" class="but">物流查询</van-button>
        <div>
          <van-button size="small" type="danger" @click="$router.push({path:'/complain',query:{orderNo:order.no}})" v-if="order.status > 1">投诉</van-button>
          <van-button style="margin-left: 8px" size="small" type="danger" @click="$router.push({path:'/refund',query:{orderNo:order.no,id:order.id}})" v-if="order.refundStatus >= 2">退款</van-button>
        </div>
      </div>
      <div style="color: #ff3b30;font-size: 12px;margin-top: 12px">{{ order.msg&&"审核消息: "+order.msg}}</div>
    </div>
    <div v-if="user && orders.length <= 1" style="padding: 12px;color: white;text-align: center;">
      没有订单了
    </div>
    <div v-if="user || (orders && orders.length)" style="display: flex;justify-content: space-around;align-items: center" class="mt16">
      <div style="cursor: pointer;" class="btn" @click="changeUser">切换用户查询</div>
      <to-soft :no="orders[0].no" v-if="orders && orders.length"/>
    </div>
    <car-input ref="carInput" @input="inputCarNum"/>
    <pay ref="pay"/>
    <van-popup v-model="tracesShow" round closeable>
      <div class="popup">
        <div class="popup-title">物流信息</div>
        <div class="popup-content">
          <van-steps direction="vertical" :active="0">
            <van-step  v-for="trace of traces">
              <h4>{{trace.content}}</h4>
              <p>{{trace.time}}</p>
            </van-step>
          </van-steps>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="showInput" round closeable>
      <div class="popup">
        <div class="popup-title">联系人信息</div>
        <div class="popup-content">
          <van-field v-model="code" label="手机号后四位" placeholder="请输入手机号后四位"></van-field>
        </div>
        <div class="popup-btn">
          <van-button size="small" type="primary" @click="getTracesOther">确认</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import FromTitle from '@/components/from-title';
import CarInput from '@/components/carInput';
import {selectAllByUserInfo} from "../api/order"
import {get} from "../api/traces"
import pay from "@/components/pay";
import ToSoft from "../components/custom/toSoft.vue";
export default {
  components: {FromTitle, CarInput,pay,ToSoft},
  data: () => {
    return {
      orders: [],
      user: null,
      form: {
        carNumber: null,
        phone: null
      },
      order:null,
      traces:[],
      tracesShow:false,
      showInput:false,
      code:null,
    }
  },
  mounted() {
    let userStr = localStorage.getItem("user");
    if (userStr) {
      this.user = JSON.parse(userStr);
    }
    //如果缓存有订单
    let orderStr = localStorage.getItem("order");
    if (orderStr) {
      //根据用户信息获取所有订单
      let order = JSON.parse(orderStr);
      this.user = {carNumber: order.userInfo.carNumber, phone: order.userInfo.phone};
      localStorage.setItem("user", JSON.stringify(this.user));
      this.getOrders();
    } else if (this.user) {
      this.getOrders();
    }
  },
  methods: {
    getTracesOther(order){
      get({traces:order.msg,code:this.code}).then(res=>{
        this.traces = res.data;
        this.tracesShow = true
        this.showInput = false
      })
    },
    getTraces(order){
      if(order.msg.includes("SF")){
        this.showInput = true
        this.order = order
        return
      }
      get({traces:order.msg}).then(res=>{
        this.traces = res.data;
        this.tracesShow = true
      })
    },
    hasTraces(order){
      return order.msg && (order.msg.includes("JD") || order.msg.includes("SF"))
    },
    toUpload(order){
      localStorage.setItem("order",JSON.stringify(order));
      this.$router.push("/upload");
    },
    getOrders() {
      selectAllByUserInfo(this.user).then(res => {
        //如果有支付过的订单 就只显示支付过的订单 否则显示全部
        let payOrders = [];
        for(let order of res.data){
          if(order.status > 1){
            payOrders.push(order);
          }
        }
        this.orders = payOrders.length ? payOrders : res.data;
      })
    },
    changeUser() {
      localStorage.removeItem("user");
      localStorage.removeItem("order");
      this.user = null;
      this.orders = [];
    },
    getOrder() {
      if (!this.form.carNumber) {
        this.$toast('请输入车牌号码');
        return;
      }
      if (!this.form.phone) {
        this.$toast('请输入手机号码');
        return;
      }
      localStorage.setItem("user", JSON.stringify(this.form));
      this.user = this.form;
      this.getOrders();
    },
    openCarInput() {
      this.$refs.carInput.show();
    },
    inputCarNum(carNum) {
      this.form.carNumber = carNum;
    },
    pay(no) {
      this.order = {no:no};
      this.$refs.pay.open(this.order);
    }
  }
};
</script>

<style lang="scss">
.order-main {
  background: #007aff;
  min-height: 100vh;
  padding-top: 12px;
  padding-bottom: 60px;
  .main {
    border-radius: 6px;
    background: #FFFFFF;
    padding: 12px;
    margin: 12px;
  }

  .order-box {
    background: white;
    border-radius: 6px;
    margin: 12px;
    padding: 12px;

    .from-title {
      justify-content: space-between;
      align-items: center;
    }

    .order-btns{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 8px;
    }
    .but{
      background: #0e77ff;
      color: white;
      border-radius: 6px;
      margin-left: 12px;
    }
    .but2{
      color: #333;
      font-size: 12px;
      padding: 4px;
    }
  }
}
.popup{
  width: 300px;
  max-height: 70vh;
  padding: 12px;

  .popup-title{
    display: flex;
    justify-content: center;
    font-size: 16px;
    font-weight:bold;
  }
  .popup-content{
    padding: 12px;
    .van-step--horizontal .van-step__circle-container, .van-steps{
      background: white;
    }
  }
  .popup-btn{
    display: flex;
    justify-content: center;
  }
}
</style>
