import request from '@/utils/request.js'
export function paging(data) {
  return request({
    url: '/api/order/paging',
    method: 'post',
    data
  })
}
export function selectAllByUserInfo(data) {
  return request({
    url: '/api/order/selectAllByUserInfo',
    method: 'post',
    data
  })
}
export function add(data) {
  return request({
    url: '/api/order/add',
    method: 'post',
    data
  })
}
export function edit(data) {
  return request({
    url: '/api/order/edit',
    method: 'post',
    data
  })
}
export function selectOne(data) {
  return request({
    url: '/api/order/selectOne',
    method: 'post',
    data
  })
}
export function refundApply(data) {
  return request({
    url: '/api/order/refundApply',
    method: 'post',
    data
  })
}