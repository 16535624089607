<template>
  <van-field v-if="show" v-model="form[attr]" :type="type" :label="label" :placeholder="placeholder" clearable/>
</template>

<script>
	export default {
    props:['type','label','placeholder','form','attr','proId'],
    data() {
      return {
        show:false,
        timer:null,
      }
    },
    mounted() {
      clearInterval(this.timer)
      this.update();
      this.timer = setInterval(()=>{
        this.update();
      },200)
    },
    methods:{
      update(){
        //组件需要值 但是表单中没有值或是不需要的值 就不显示
        if(this.proId && (!this.form.proId || this.form.proId === -1 || this.proId !== this.form.proId)){
          this.show = false;
          return
        }
        this.show = true;
      }
    }
  };
</script>

<style lang="scss">
.from-title{
  display: flex;
  align-items: center;
  height: 40px;
  .title{
    border-left: 2px #007aff solid;
    padding:0 6px;
    line-height: 70%;
    color: #333;
    font-weight: bold;
    font-size: .9rem;
  }
  .subtitle{
    color: #999;
    font-size: 0.8rem;
  }
}
</style>
