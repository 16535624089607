<template>
  <div>
    <wx-open-launch-weapp
        id="launch-btn"
        :appid="toAppid"
        :path="'pages/index/index?no='+no"
        env-version="release"
        @error="wxErr"
        v-if="isWx"
    >
      <script type="text/wxtag-template">
        <style>
          .soft-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            background: linear-gradient(360deg, #3F4BFF 0%, #5777FE 100%);
            border-radius: 27px;
            font-size: 14px;
            color: #fff;
            width: 130px;
            height: 40px;
            -webkit-animation-name: scaleDraw;
            -webkit-animation-timing-function: ease-in-out;
            -webkit-animation-iteration-count: infinite;
            -webkit-animation-duration: 5s;
            box-shadow: 1px 1px 2px #FFFFFF;
            margin: 24px auto;
            outline: none;
            border: 0;
          }
        </style>
        <button class="soft-btn">{{softBtnText ? softBtnText : '订阅审核通知'}}</button>
      </script>
    </wx-open-launch-weapp>
    <!-- release   develop-->
    <a v-else class="soft-btn" :href="`weixin://dl/business/?appid=${toAppid}&path=pages/index/index&query=no=${no}&env_version=release`">{{softBtnText ? softBtnText : '订阅审核通知'}}</a>
  </div>
</template>
<script>
import {getSign} from "@/api/wx";
export default {
  props:['softBtnText','no'],
  data() {
    return {
      isWx:false,
      toAppid:null
    }
  },
  mounted() {
    let ua = navigator.userAgent.toLowerCase()
    //微信浏览器
    this.isWx = ua.match(/MicroMessenger/i) == 'micromessenger'
    let url = location.href.split('#')[0]
    getSign({url: url}).then(res => {
      if (res.code === 200) {
        this.toAppid = res.data.toAppid
        if(this.isWx){
          wx.config({
            debug: false,//为true 可以增加error事件 查询日志
            appId: res.data.appid,
            timestamp: res.data.timestamp,
            nonceStr: res.data.nonceStr,
            signature: res.data.signature,
            jsApiList:['chooseImage', 'previewImage'], // 必填，需要使用的JS接口列表
            openTagList: ['wx-open-launch-weapp'] // 可选，需要使用的开放标签列表
          })
        }
      }
    })

  },
  methods:{
    wxErr(e){
      alert(e)
    }
  }
};
</script>
<style scoped lang="scss">
.soft-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(360deg, #3F4BFF 0%, #5777FE 100%);
  border-radius: 27px;
  font-size: 14px;
  color: #fff;
  width: 130px;
  height: 40px;
  -webkit-animation-name: scaleDraw;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 5s;
  box-shadow: 1px 1px 2px #FFFFFF;
  margin: 24px auto;
  outline: none;
  border: 0;
}
</style>