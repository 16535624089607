<template xmlns="element-ui">
  <van-radio-group v-model="form[attr]" class="car-color-group">
    <van-radio name="1">蓝牌</van-radio>
    <van-radio name="2">绿牌</van-radio>
    <van-radio name="3">黄牌</van-radio>
  </van-radio-group>
</template>
<script>
	export default {
    props:['form','attr']
	};
</script>

<style lang="scss">
.car-color-group{
  display: flex;
  justify-content: space-between;
}
</style>
