<template>
  <div class="notice-bar-container">
    <van-notice-bar class="notice-bar-box" color="#333333" background="#ffffff" left-icon="volume-o"
                    :scrollable="false">
      <van-swipe
          vertical
          class="notice-swipe"
          :autoplay="2000"
          :speed="10"
          :show-indicators="false"
      >
        <van-swipe-item v-for="item in news" :key="item.id">{{ hideCenter(item.userInfo.carNumber, 2, 2) }}
          {{ diffTime(item.updateTime) }} 办理成功!
        </van-swipe-item>
      </van-swipe>
    </van-notice-bar>
  </div>
</template>

<script>
import {paging} from "@/api/order";

export default {
  props: {},
  data: function () {
    return {
      news: []
    };
  },
  mounted() {
    //获取最新10条订单
    paging({statusReq: 1}).then(res => {
      this.news = res.data.data;
    })
  },
  methods: {}
};
</script>

<style lang="scss">
.notice-bar-container {
  padding: 12px;

  .notice-bar-box {
    border-radius: 4px;
  }

  .notice-swipe {
    height: 40px;
    line-height: 40px;
  }
}

</style>
