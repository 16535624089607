<template>
  <div class="upload-item">
    <van-uploader accept="image/*" :after-read="upload">
      <van-image :src="form[attr]?form[attr]:defaultImg" width="100%"/>
    </van-uploader>
    <span class="tip">{{tip}}</span>
  </div>
</template>

<script>
import {upload} from '@/api/sys/upload';

export default {
  props: ['defaultImg','tip','form','attr'],
  methods: {
    upload(file) {
      this.$toast.loading({
        message: '上传中...',
        forbidClick: true,
      });
      // 声明form表单数据
      const formData = new FormData();
      // 添加文件信息
      formData.append('file', file.file);
      upload(formData).then(res => {
        this.form[this.attr] = res.data;
        this.$toast.clear();
        this.$toast.success("上传成功!");
        this.$forceUpdate()
      }).catch(err=>{
        this.$toast.fail("上传失败.e:"+err);
      })
    }
  }
};
</script>

<style lang="scss">
.upload-item{
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 400;
  font-stretch: normal;
  color: #000;

  .tip{
    margin: 12px 0;
  }
}
</style>
