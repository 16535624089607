<template>
  <div>
    <van-checkbox v-model="form[attr]" icon-size="18px">
      <div class="agree-box">
        我已阅读并同意
        <span class="xy" v-for="agree of agrees" @click.stop="openProp(agree)">《{{agree.name}}》</span>
      </div>
    </van-checkbox>
    <van-popup
        v-model="showProp"
        closeable
        close-icon="close"
        position="bottom"
        :style="{ height: '80%' }"
    >
      <p v-if="curAgree" v-html="curAgree.content" style="padding: 12px"></p>
    </van-popup>
  </div>
</template>
<script>
import {selectAll} from "@/api/agree";
export default {
  props:['form','attr'],
  data() {
    return {
      showProp:false,
      agrees:[],
      curAgree:null,
    }
  },
  mounted() {
    selectAll().then(res=>{
      this.agrees = res.data;
    })
    //小程序过审 不能默认同意用户协议
    this.form[this.attr] = true;
  },
  methods: {
    openProp(agree){
      this.showProp = true;
      this.curAgree = agree;
      this.$forceUpdate()
    },
  }
};
</script>

<style lang="scss">
.agree-box {
  font-size: 12px;
  vertical-align: middle;
  font-weight: 400;
  font-stretch: normal;
  letter-spacing: 1px;
  color: #000;
  line-height: 23px;
  padding-left: 12px;
}
.xy {
  font-size: 11px;
  font-weight: 400;
  font-stretch: normal;
  letter-spacing: 1px;
  color: #0e77ff;
  z-index: 1;
  line-height: 18px;
}
</style>
