<template>
  <div class="address-box">
    <div style="display: flex;align-items: center;">
      <van-icon name="location-o" size="48" color="#007aff"/>
      <div class="address-main">
        <div>{{ form['name']||'姓名' }}<span class="other-info ml8">{{ form['phone']||'手机号' }}</span></div>
        <div class="other-info">{{ form['province,city,area']||'地区' }}</div>
        <div class="other-info">{{ form['address']||'详细地址' }}</div>
      </div>
    </div>
  </div>
</template>

<script>
	export default {
    props:['form']
	};
</script>

<style lang="scss">
.address-box {
  background: white;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .address-main {
    margin-left: 12px;
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 700;
    color: #333;
    line-height: 29px;

    .other-info {
      font-size: 13px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #999;
      line-height: 20px;
    }
  }
}
</style>
