<template>
	<div style="display: flex;justify-content: center;align-items: center;margin-top: 14px;font-size: 12px;height: 100vh;">
    微信支付中,请勿操作...
	</div>
</template>

<script>
import {pay} from "../api/pay";
  let that;
	export default {
    mounted() {
      that = this;
      pay({code: this.getQueryString("code"),state:this.getQueryString("state")}).then(res => {
        if(res.code !== 200){
          this.$toast({
            message: res.message,
          })
          setTimeout(()=>{
            that.$router.go(-1);
          },1500);
          return;
        }
        if (typeof WeixinJSBridge === 'undefined') {
          if (document.addEventListener) {
            document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady(res.data), false)
          } else if (document.attachEvent) {
            document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady(res.data))
            document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady(res.data))
          }
        } else {
          this.onBridgeReady(res.data);
        }
      })
    },
    methods: {
      onBridgeReady(data){
        if(typeof window.WeixinJSBridge === 'undefined'){
          setTimeout(()=>{
            that.onBridgeReady(data)
          },100);
          return;
        }
        setTimeout(()=>{
          window.WeixinJSBridge.invoke('getBrandWCPayRequest', {
                "appId": data.appId,
                "timeStamp": data.timeStamp, //时间戳，自1970年以来的秒数
                "nonceStr": data.nonceStr, //随机串
                "package": data.package, // prepay_id=xxx
                "signType": data.signType||"RSA", //微信签名方式：
                "paySign": data.paySign //微信签名
              },
              function (r) {
                that.$router.go(-1);
              }
          );
        },100);
      }
    }
  };
</script>

<style>
	.my-content-container{
		padding: 12px;
    color: #333;
    font-size: 13px;
    line-height: 30px;
	}
  .van-cell{
    padding: 10px 0;
  }
</style>
