<template>
  <div class="pay-container">
    <van-popup v-model="show" position="bottom">
      <van-radio-group v-model="radio">
        <van-cell-group>
          <van-cell clickable center @click="radio = 1" v-if="config.wepay == 1">
            <template #title>
              <div style="display: flex;align-items: center;margin: 0 12px">
                <van-image :src="require('@/assets/img/wx.png')" style="width: 1rem;height: 1rem;margin-right:.5rem;"/>
                微信支付
              </div>
            </template>
            <template #right-icon>
              <van-radio :name="1"/>
            </template>
          </van-cell>
          <van-cell center clickable @click="radio = 2" v-if="config.alipay == 1">
            <template #title>
              <div style="display: flex;align-items: center;margin: 0 12px">
                <van-image :src="require('@/assets/img/zfb.png')" style="width: 1rem;height: 1rem;margin-right:.5rem;"/>
                支付宝支付
              </div>
            </template>
            <template #right-icon>
              <van-radio :name="2"/>
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
      <div style="display: flex;justify-content: center">
        <van-button @click="pay" round plain type="info" style="width: 50%;margin: 1rem 0">支付</van-button>
      </div>
    </van-popup>
  </div>
</template>
<script>
import {create, alipay, pay, getCode} from "../api/pay";

export default {
  name: "pay",
  data: () => {
    return {
      show: false,
      radio: 1,
      order: null,
      config: {
        wepay: null,
        alipay: null
      },
    }
  },
  created() {
    this.config = JSON.parse(localStorage.getItem("config"));
  },
  methods: {
    open(order) {
      this.show = true;
      this.order = order;
    },
    pay() {
      //记录一个标记  代表用户选择了支付方式
      localStorage.setItem("payType", this.radio);
      //关闭弹窗
      this.show = false;
      let return_url = location.href.replace("#", "_");
      this.$toast("前往支付...");
      switch (this.radio) {
        case 1:
          let ua = navigator.userAgent.toLowerCase()
          //微信浏览器
          if (ua.match(/MicroMessenger/i) == 'micromessenger') {
            getCode(this.order.no);
          } else {
            //H5 创建预支付订单
            create({orderNo: this.order.no}).then(res => {
              location.href = res.data.h5_url;
            })
          }
          break;
        case 2:
          this.order.return_url = return_url;
          alipay({orderNo: this.order.no, return_url: return_url}).then(res => {
            document.write(res.data);
          })
          break;
      }
    }
  }
}
</script>

<style lang="scss">
::v-deep .van-cell {
  display: flex;
  align-items: center;
  line-height: 2rem;
}
</style>