<template>
  <div class="order-box" v-if="form['proId']">
    <van-card
        v-if="product.img"
        num="1"
        :price="product.realPrice.toFixed(2)"
        desc="数量：1"
        :title="product.name"
        :thumb="product.img"
    />
    <div v-else class="pro-box">
      <div class="pro-item">
        <div class="tip">{{ product.name }}</div>
        <div class="price">¥ {{ product.price }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import * as proApi from '@/api/product'
export default {
  props:['form'],
  data() {
    return {
      product:{},
      timer:null,
      cache:{}
    };
  },
  mounted() {
    clearInterval(this.timer)
    this.update();
    this.timer = setInterval(()=>{
      this.update();
    },350)
  },
  destroyed() {
    clearInterval(this.timer)
  },
  methods: {
    update(){
      //如果存在产品 就查产品
      if(this.form['proId'] && this.form['proId'] !== -1){
        if(this.cache[this.form['proId']]){
          this.product = this.cache[this.form['proId']];
          return;
        }
        proApi.selectOne({id:this.form['proId']}).then(res=>{
          this.product = res.data
          this.cache[this.form['proId']] = this.product
          this.$forceUpdate()
        })
      }
    }
  }
};
</script>

<style lang="scss">
.order-box {
  background: white;
  border-radius: 6px;
}
.pro-box {
  .pro-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;

    .price {
      font-weight: bold;
      color: #ff3b30;
    }
  }
}
</style>