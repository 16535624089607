<script>
import Inputor from "@/components/custom/inputor";
import ProductList from "@/components/custom/product-list";
import ProductList2 from "@/components/custom/product-list2";
import FormTitle from "@/components/custom/title";
import City from "@/components/custom/city";
import CarNumber from "@/components/custom/car/index";
import CarColor from "@/components/custom/car-color";
import Agree from "@/components/custom/agree";
import Uploader from "@/components/custom/uploader";
import Overlay from "@/components/custom/overlay";
import UserInfo from "@/components/custom/user-info";
import OrderInfo from "@/components/custom/order-info";
import Result from "@/components/custom/result";
import Jump from "@/components/custom/jump";
import Contact from "@/components/custom/contact.vue";
export default {
  components: {
    Inputor,
    ProductList,
    ProductList2,
    FormTitle,
    CarNumber,
    CarColor,
    City,
    Agree,
    Uploader,
    Overlay,
    UserInfo,
    OrderInfo,
    Result,
    Jump,
    Contact
  },
  props: ['rule', 'form'],
  data() {
    return {
      elements: [],
    }
  },
  mounted() {
    this.elements = JSON.parse(this.rule.content);
    if(!this.form.proId ||this.form.proId === -1){
      this.form.proId = this.rule.proId;
    }
  },
  render(h) {
    return this.render(h, this.elements)
  },
  methods: {
    render(h, elements) {
      let els = [];
      for (let element of elements) {
        if (element.tag === 'input') {
          els.push((
              <inputor style={`
              width: calc(${element.width} - ${element.paddingLeft} - ${element.paddingRight});
              padding: ${element.paddingTop} ${element.paddingRight} ${element.paddingBottom} ${element.paddingLeft};
              margin: ${element.marginTop} ${element.marginRight} ${element.marginBottom} ${element.marginLeft};
            `} form={this.form} attr={element.attr} type={element.type} label={element.label}
                 proId={element.proId}  placeholder={element.placeholder}/>
          ))
        } else if (element.tag === 'car-color') {
          els.push((
              <car-color style={`
              width: calc(${element.width} - ${element.paddingLeft} - ${element.paddingRight});
              padding: ${element.paddingTop} ${element.paddingRight} ${element.paddingBottom} ${element.paddingLeft};
              display: ${element.display};
            `} form={this.form} attr={element.attr}/>
          ))
        } else if (element.tag === 'next-button') {
          els.push((
              <div style="display: flex;justify-content: center">
                <div class="next-btn mt16" onClick={() => this.nextStep()}>下一步</div>
              </div>
          ))
        } else if (element.tag === 'jump') {
          els.push((
              <jump style={`
              width: calc(${element.width} - ${element.paddingLeft} - ${element.paddingRight});
              padding: ${element.paddingTop} ${element.paddingRight} ${element.paddingBottom} ${element.paddingLeft};
              margin: ${element.marginTop} ${element.marginRight} ${element.marginBottom} ${element.marginLeft};
            `} form={this.form} attr={element.attr}/>
          ))
        }else if(element.tag === 'contact'){
          els.push((
              <contact form={this.form} attr={element.attr}/>
          ))
        } else if (element.tag === 'pay-button') {
          els.push((
              <div style="display: flex;justify-content: center">
                <div class="next-btn mt16" onClick={() => this.pay()}>立即支付</div>
              </div>
          ))
        } else if (element.tag === 'user-info') {
          els.push((
              <user-info style={`
              width: calc(${element.width} - ${element.paddingLeft} - ${element.paddingRight});
              padding: ${element.paddingTop} ${element.paddingRight} ${element.paddingBottom} ${element.paddingLeft};
              margin: ${element.marginTop} ${element.marginRight} ${element.marginBottom} ${element.marginLeft};
            `} form={this.form}/>
          ))
        } else if (element.tag === 'order-info') {
          els.push((
              <order-info ref="orderInfo" style={`
              width: calc(${element.width} - ${element.paddingLeft} - ${element.paddingRight});
              padding: ${element.paddingTop} ${element.paddingRight} ${element.paddingBottom} ${element.paddingLeft};
              margin: ${element.marginTop} ${element.marginRight} ${element.marginBottom} ${element.marginLeft};
            `} form={this.form}/>
          ))
        } else if (element.tag === 'title') {
          els.push((
              <form-title title={element.label} subtitle={element.subtitle}/>
          ))
        } else if (element.tag === 'car-number') {
          els.push((
              <car-number style={`
              width: calc(${element.width} - ${element.paddingLeft} - ${element.paddingRight});
              padding: ${element.paddingTop} ${element.paddingRight} ${element.paddingBottom} ${element.paddingLeft};
              margin: ${element.marginTop} ${element.marginRight} ${element.marginBottom} ${element.marginLeft};
            `} form={this.form} attr={element.attr} disabled={element.disabled}/>
          ))
        } else if (element.tag === 'product-list') {
          els.push((
              <product-list form={this.form} attr={element.attr} type={element.type} style={{width: element.width}}/>
          ))
        } else if (element.tag === 'product-list2') {
          els.push((
              <product-list2 form={this.form} attr={element.attr} type={element.type} style={{width: element.width}}/>
          ))
        } else if (element.tag === 'city') {
          els.push((
              <city style={`
              width: calc(${element.width} - ${element.paddingLeft} - ${element.paddingRight});
              padding: ${element.paddingTop} ${element.paddingRight} ${element.paddingBottom} ${element.paddingLeft};
              margin: ${element.marginTop} ${element.marginRight} ${element.marginBottom} ${element.marginLeft};
            `} form={this.form} attr={element.attr} proId={element.proId}/>
          ))
        } else if (element.tag === 'agree') {
          els.push((
              <agree style={`
              width: calc(${element.width} - ${element.paddingLeft} - ${element.paddingRight});
              padding: ${element.paddingTop} ${element.paddingRight} ${element.paddingBottom} ${element.paddingLeft};
              margin: ${element.marginTop} ${element.marginRight} ${element.marginBottom} ${element.marginLeft};
            `} form={this.form} attr={element.attr}/>
          ))
        } else if (element.tag === 'uploader') {
          els.push((
              <uploader defaultImg={element.defaultImg} tip={element.tip} style={`
              width: calc(${element.width} - ${element.paddingLeft} - ${element.paddingRight});
              padding: ${element.paddingTop} ${element.paddingRight} ${element.paddingBottom} ${element.paddingLeft};
              margin: ${element.marginTop} ${element.marginRight} ${element.marginBottom} ${element.marginLeft};
            `} form={this.form} attr={element.attr}/>
          ))
        } else if (element.tag === 'overlay') {
          els.push((
              <overlay/>
          ))
        } else if (element.tag === 'result') {
          els.push((
              <result mainTip={element.mainTip} tip={element.tip} otherTip={element.otherTip}
                      defaultImg={element.defaultImg} showSoftBtn={element.showSoftBtn}
                      softBtnText={element.softBtnText}  form={this.form}/>
          ))
        } else if (element.tag === 'steps') {
          if (typeof element.steps === 'string') {
            element.steps = element.steps.split(',')
          }
          els.push((
              <van-steps style={`
              width: calc(${element.width} - ${element.paddingLeft} - ${element.paddingRight});
              padding: ${element.paddingTop} ${element.paddingRight} ${element.paddingBottom} ${element.paddingLeft};
              margin: ${element.marginTop} ${element.marginRight} ${element.marginBottom} ${element.marginLeft};
              background: ${element.background};
            `} active={element.active} active-color={element.activeColor} inactive-color={element.inactiveColor}>
                {element.steps.map(item => {
                  return (
                      <van-step>{item}</van-step>
                  )
                })}
              </van-steps>
          ))
        } else if (element.tag === 'one-col') {
          els.push((
              <div style={
                `
               width: calc(${element.width} - ${element.paddingLeft} - ${element.paddingRight});
              min-height: ${element.height};
              padding: ${element.paddingTop} ${element.paddingRight} ${element.paddingBottom} ${element.paddingLeft};
              margin: ${element.marginTop} ${element.marginRight} ${element.marginBottom} ${element.marginLeft};
              background-color: ${element.background};
              border-radius: ${element.radius}px;
              `}>
                {element.children.map(item => {
                  return (
                      <div class="clone-element">
                        {this.render(h, [item])}
                      </div>
                  )
                })}
              </div>
          ))
        } else if (element.tag === 'more-col') {
          els.push((
              <div style={
                `
            width: calc(${element.width} - ${element.paddingLeft} - ${element.paddingRight});
            min-height: ${element.height};
            padding: ${element.paddingTop} ${element.paddingRight} ${element.paddingBottom} ${element.paddingLeft};
            background-color: ${element.background};
            border-radius: ${element.radius}px;
            display: flex;
          `}>
                {element.children.map(item => {
                  return (
                      <div class="clone-element">
                        {this.render(h, [item])}
                      </div>
                  )
                })}
              </div>
          ))
        }
      }
      return (
          <div>
            {els.map(item => {
              return item
            })}
          </div>
      )
    },
    nextStep() {
      this.$emit('next-step')
    },
    pay() {
      this.$emit('pay')
    }
  }
}
</script>
<style lang="scss">
.van-step--horizontal .van-step__circle-container {
  background: rgba(0, 0, 0, 0) !important;
}

.clone-element {
  width: 100%;
}
</style>