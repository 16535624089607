import request from '@/utils/request.js'

export function refund(data) {
  return request({
    url: '/api/refund/refund',
    method: 'post',
    data
  })
}
export function selectOne(data) {
  return request({
    url: '/api/refund/selectOne',
    method: 'post',
    data
  })
}