import Vue from 'vue'
import VueRouter from 'vue-router'
import NotFound from '../pages/404.vue' //登录页
import Home from '../pages/home.vue' //首页
// import UserInfo from '../pages/userInfo'
// import UserInfo2 from '../pages/userInfo2'
// import Address from '../pages/address'
// import Product from '../pages/product'
// import Pay from '../pages/pay'
import Order from '../pages/order'
import Upload from '../pages/upload'
// import Rule2Upload from '../pages/rule2upload'
// import Result from "../pages/result";
import Complain from "../pages/complain.vue";
import Refund from "../pages/refund.vue";
import ToSmallSoft from "../pages/toSmallSoft"
import BankUpload from "../pages/BankUpload"
import My from "../pages/my"
import Content from "../pages/content"
import Login from "../pages/login"

import weixinPay from "../pages/weixin-pay.vue";
import weixinLogin from "../pages/weixin-login.vue";
import DyView from "../pages/dy-view.vue";
Vue.use(VueRouter)
const routes = [{
    path: '/',
    name: '/',
    redirect: '/home'
}, {
    path: '/404',
    name: '404',
    component: NotFound
},
    {
        path: '/home',
        name: '全国高速ETC办理中心',
        component: Home
    },
    // {
    //     path: '/userInfo',
    //     name: '收货信息',
    //     component: UserInfo
    // },
    // {
    //     path: '/userInfo2',
    //     name: '车牌号登记',
    //     component: UserInfo2
    // },
    // {
    //     path: '/address',
    //     name: '修改收货信息',
    //     component: Address
    // },
    // {
    //     path: '/product',
    //     name: '选择设备',
    //     component: Product
    // },
    // {
    //     path: '/pay',
    //     name: '物流配送',
    //     component: Pay
    // },
    {
        path: '/upload',
        name: '上传资料',
        component: Upload
    },
    // {
    //     path: '/rule2upload',
    //     name: '上传车头照',
    //     component: Rule2Upload
    // },
    {
        path: '/toSmallSoft',
        name: '前往小程序',
        component: ToSmallSoft
    },
    // {
    //     path: '/result',
    //     name: '提交成功',
    //     component: Result
    // },
    {
        path: '/login',
        name: '登录',
        component: Login
    },
    {
        path: '/bankUpload',
        name: '上传银行卡信息',
        component: BankUpload
    },
    {
        path: '/order',
        name: '我的订单',
        component: Order
    },
    {
        path: '/my',
        name: '我的',
        component: My
    },
    {
        path: '/content',
        name: '内容',
        component: Content
    },
    {
        path: '/dy-view',
        name: '全国高速ETC办理',
        component: DyView
    },
    {
        path: '/weixin-pay',
        name: '微信支付',
        component: weixinPay
    },
    {
        path: '/weixin-login',
        name: '微信登录',
        component: weixinLogin
    },
    {
        path: '/complain',
        name: '投诉',
        component: Complain
    },
    {
        path: '/refund',
        name: '退款',
        component: Refund
    }
]
const router = new VueRouter({
    mode: 'hash',
    // mode: 'history',
    base: process.env.BASE_URL,
    routes
})
router.beforeEach((to,from,next)=>{
    if(to.name){
        document.title = to.name
    }
    next()
})
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
export default router
