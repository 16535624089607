<template>
  <div style="background: white;border-radius: 4px;margin-top: 12px;padding: 12px;">
    <form-title title="紧急联系人" subtitle=""/>
    <form-title title="紧急联系人1" subtitle=""/>
    <van-field v-model="forms[0].name" label="姓名" placeholder="请输入联系人姓名" clearable/>
    <van-field v-model="forms[0].phone" type="number" label="电话" placeholder="请输入联系人电话" clearable/>
    <van-field readonly v-model="forms[0].relation" label="关系" placeholder="请选择联系人关系" clearable @click="open(0)"/>
    <form-title title="紧急联系人2" subtitle=""/>
    <van-field v-model="forms[1].name" label="姓名" placeholder="请输入联系人姓名" clearable/>
    <van-field v-model="forms[1].phone" type="number" label="电话" placeholder="请输入联系人电话" clearable/>
    <van-field readonly v-model="forms[1].relation" label="关系" placeholder="请选择联系人关系" clearable @click="open(1)"/>
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker
          title="联系人关系"
          show-toolbar
          :columns="relations"
          value-key="name"
          @confirm="onConfirm"
          @cancel="showPicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
import FormTitle from "@/components/custom/title";

export default {
  components: {
    FormTitle
  },
  props: ['form','attr'],
  data() {
    return {
      forms: [{
        name: null,
        phone: null,
        relation: null
      }, {
        name: null,
        phone: null,
        relation: null
      }],
      relations: ["父母", "配偶", "子女", "兄弟姐妹", "朋友", "同事", "同学", "其他"],
      showPicker:false,
      index:0
    }
  },
  methods: {
    onConfirm(value) {
      this.forms[this.index].relation = value
      this.showPicker = false
      this.form[this.attr] = this.forms
    },
    open(index){
      this.showPicker = true;
      this.index = index
    }
  }
};
</script>

<style lang="scss">
.from-title {
  display: flex;
  align-items: center;
  height: 40px;

  .title {
    border-left: 2px #007aff solid;
    padding: 0 6px;
    line-height: 70%;
    color: #333;
    font-weight: bold;
    font-size: .9rem;
  }

  .subtitle {
    color: #999;
    font-size: 0.8rem;
  }
}
</style>
