<template>
  <div
      style="display: flex;justify-content: center;align-items: center;margin-top: 14px;font-size: 12px;height: 100vh;">
    微信授权中...
  </div>
</template>

<script>
import {pay} from "../api/pay";

let that;
export default {
  mounted() {
    that = this;
    let code = this.$route.query.code || this.getQueryString("code");
    let status = this.$route.query.code || this.getQueryString("state");
    alert(code + "," + status)
  },
  methods: {}
};
</script>

<style>
.my-content-container {
  padding: 12px;
  color: #333;
  font-size: 13px;
  line-height: 30px;
}

.van-cell {
  padding: 10px 0;
}
</style>
