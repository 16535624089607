import request from '@/utils/request.js'

export function add(data) {
  return request({
    url: '/api/complaint/add',
    method: 'post',
    data
  })
}
export function selectOne(data) {
  return request({
    url: '/api/complaint/selectOne',
    method: 'post',
    data
  })
}
export function reply(data) {
  return request({
    url: '/api/complaint-reply/add',
    method: 'post',
    data
  })
}