<template>
	<div id="App">
    <router-view/>
    <van-tabbar route v-model="active" @change="changeTabbar">
      <van-tabbar-item icon="home-o" replace to="/home">首页</van-tabbar-item>
      <van-tabbar-item icon="service-o" @click="goKefu"><a href="#">客服</a></van-tabbar-item>
      <van-tabbar-item icon="orders-o" to="/order">订单</van-tabbar-item>
      <van-tabbar-item icon="manager-o" to="/my">我的</van-tabbar-item>
    </van-tabbar>
	</div>
</template>
<script>
  import {selectOne} from "@/api/config";
  export default {
		components: {},
		data() {
			return {
        active: 0,
        config:{
          chatUrl:''
        }
      }
		},
		mounted() {
      selectOne().then(res=>{
        this.config = res.data;
        localStorage.setItem("config",JSON.stringify(this.config))
      })
		},
		methods: {
      goKefu(){
        location.href=this.config.chatUrl
      },
      changeTabbar(val){
        this.active = val;
      }
		}
	}
</script>
<style>
*{
  padding: 0;
  margin: 0;
}
@media screen and (min-width:750px){
  #App{
    max-width: 750px;
    margin: 0 auto;
  }
}

.ml8{
  margin-left: 8px;
}
.van-step--horizontal .van-step__circle-container,
.van-steps {
  background: #007aff;
}

.van-step--horizontal .van-step__title {
  font-size: 0.9rem;
  font-weight: bold;
}
.van-step--finish{
  color: white;
}
.next-btn{
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(360deg, #3F4BFF 0%, #5777FE 100%);
  border-radius: 27px;
  font-size: 18px;
  color: #fff;
  width: 130px;
  height: 40px;
  -webkit-animation-name: scaleDraw;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 5s;
  margin: 0 auto;
  box-shadow: 1px 1px 2px #FFFFFF;
  position: fixed;
  bottom: 88px;
  z-index: 2;
}
@keyframes scaleDraw {  /*定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称*/
  0%{
    transform: scale(1);  /*开始为原始大小*/
  }
  25%{
    transform: scale(1.1);
  }
  50%{
    transform: scale(1);
  }
  75%{
    transform: scale(1.1);
  }
}
</style>
