<template>
  <div>
    <build v-for="(item,index) in rules" :key="index" :item="item"
           :rule="item"
           v-if="page === item.page && (item.proId == form['proId'] || item.proId == -1)"
           :form="form"
           @next-step="nextStep(item)"
    />
    <pay ref="pay"/>
  </div>
</template>

<script>
import Build from "@/components/custom/build";
import pay from "@/components/pay";
import {selectAllByUpload} from "@/api/pro-rule-page";
import {edit,selectOne} from "@/api/order";
export default {
  components: {
    Build, pay
  },
  data() {
    return {
      form: {},
      page: 1,
      rules: [],
      order:null
    }
  },
  mounted() {
    let id = this.$route.query.id
    if (id) {
      selectOne({id: id}).then(res => {
        this.order = res.data
        console.log(this.order)
        this.initData()
      })
    }else{
      this.order = JSON.parse(localStorage.getItem("order"));
      this.initData()
    }
  },
  methods:{
    initData(){
      this.form = {
        type: this.order.type,
        proId: this.order.proId,
      }
      if(this.order.attrs && this.order.attrs.length){
        for(let attr of this.order.attrs){
          switch (attr.type){
            case 1:
              this.form["url1"] = attr.src
              break;
            case 2:
              this.form["url2"] = attr.src
              break;
            case 3:
              this.form["url3"] = attr.src
              break;
            case 4:
              this.form["url4"] = attr.src
              break;
            case 5:
              this.form["url5"] = attr.src
              break;
          }
        }
      }
      if(this.order.bank){
        this.form["bank_name"] = this.order.bank.name;
        this.form["bank_phone"] = this.order.bank.phone;
        this.form["bank_num"] = this.order.bank.bankNum;
        this.form["bank_card"] = this.order.bank.card;
        this.form["bank_code"] = this.order.bank.code;
      }
      //查找 从上传开始的页面
      selectAllByUpload({type: this.order.type}).then(res => {
        this.rules = res.data;
        this.page = res.data[0].page;
      })
    },
    nextStep(rule) {
      let elements = JSON.parse(rule.content);
      if (this.validate(elements)) {
        let order = {
          type:this.form['type'],
          proId:this.form['proId'],
          inUrl:this.form['inUrl'],
          contacts:this.form['contacts'],
          bank:{
            name:this.form['bank_name'],
            phone:this.form['bank_phone'],
            bankNum:this.form['bank_num'],
            card:this.form['bank_card'],
            code:this.form['bank_code']
          },
          attrReq:{
            url1:this.form['url1'],
            url2:this.form['url2'],
            url3:this.form['url3'],
            url4:this.form['url4'],
            url5:this.form['url5']
          },
          userInfo:{
            carNumber:this.form['carNumber'],
            carType:this.form['carType'],
            name:this.form['name'],
            phone:this.form['phone'],
            province:this.form['province'],
            city:this.form['city'],
            area:this.form['area'],
            address:this.form['address'],
            card:this.form['card']
          }
        }
        order.id = this.order.id;
        edit(order).then(res => {
          if(res.code === 200){
            this.page++;
          }
        })
      }
    },
    validate(elements) {
      //将数组展开平放
      elements = this.arrExpand(elements, []);
      for (let ele of elements) {
        //有绑定属性和错误提示
        if (ele.err && ele.attr && (!this.form[ele.attr] || this.form[ele.attr] === -1)) {
          if(this.form['jump'] && (ele.attr === 'url1' || ele.attr === 'url2' || ele.attr === 'url3' || ele.attr === 'url4' || ele.attr === 'url5')){
            continue;
          }
          this.$toast({
            message: ele.err,
          })
          return false
        }
      }
      return true
    },
    arrExpand(srcArr, targetArr) {
      for (let ele of srcArr) {
        if (ele.children && ele.children.length) {
          this.arrExpand(ele.children, targetArr);
        }
        targetArr.push(ele);
      }
      return targetArr;
    },
  }
}
</script>

<style lang="scss">
.upload-main{
  background: #007aff;
  min-height: 100vh;
  padding-bottom: 62px;

  .upload-main-box{
    margin: 12px;
    padding: 12px;
    border-radius: 6px;
    background: white;
    .car,
    .sfz{
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }
    .upload-item{
      width: 48%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: 400;
      font-stretch: normal;
      color: #000;

      .tip{
        margin: 12px 0;
      }
    }
  }
  .mark-box{
    display: flex;
    justify-content: center;
    align-items: center;

    .mark-main{
      width: calc(100vw - 4rem);
      border-radius: 8px;
      overflow: hidden;
      background: #FFFFFF;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .tip1{
        font-size: 21px;
        font-family: PingFang SC;
        font-weight: 700;
        color: #333;
        margin: 12px 0;
      }
      .img2{
        margin: 0 12px;
      }
      .tip2{
        width: calc(100% - 24px);
        margin: 0 12px;
        display: flex;
        justify-content: space-between;
        font-size: 10px;
        font-family: PingFang SC;
        font-weight: 300;
        color: #666;
      }
      .btn3{
        width: 217px;
        height: 43px;
        border-radius: 6px;
        line-height: 43px;
        text-align: center;
        background: #1677ff;
        color: #fff;
        margin: 12px 0;
      }
      .tip3{
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #999;
        margin-bottom: 12px;
      }
    }
  }
}
</style>
