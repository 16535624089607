<template>
  <div class="result-main">
    <van-icon name="success" size="64" color="#007aff" class="result-icon"/>
    <div class="tip">初审通过!</div>
    <div class="tip">请您用微信识别下方二维码上传资料完成绑定，通过后我们会马上给您寄送快递。
      1-3天送达，收到之后把etc设备贴到车前挡风玻璃上插入etc卡即可使用。</div>
    <van-image :src="src" width="50%"/>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      src:require('@/assets/img/soft.png')
    }
  },
  created() {
  },
  methods: {
  }
};
</script>

<style lang="scss">
.result-main{
  background: #007aff;
  min-height: calc(100vh - 28px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 16px 12px 12px 12px;
  .result-icon{
    background: #23da23;
    border-radius: 50%;
  }
  .tip{
    color: white;
    width: 75%;
    text-align: center;
    margin: 12px 0;
  }
}
</style>
