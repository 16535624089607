<template>
  <div class="order-container" v-if="order != null && order.product != null">
    <div class="order-info">
      <van-image width="160px" :src="order.product.img"></van-image>
      <div class="name">{{order.product.name}}</div>
    </div>
    <from-title title="退款"/>
    <van-cell title="订单号">{{order.no}}</van-cell>
    <van-cell title="支付金额">{{refund.money}}</van-cell>
    <van-cell title="下单时间">{{formatDate(order.createTime)}}</van-cell>
    <van-cell title="支付类型">{{order.payType === 1 ? "支付宝" : "微信"}}</van-cell>
    <van-cell title="退款手续费">{{refund.fee}}</van-cell>
    <van-cell title="退款金额">{{refund.realMoney}}</van-cell>
    <van-cell title="退款周期">{{config.refundTime}}工作日</van-cell>
    <van-cell title="退款状态" v-if="order.refundStatus === 3">退款申请已提交,请耐心等待</van-cell>
    <van-cell title="退款状态" v-if="order.refundStatus === 4">退款处理中,请耐心等待</van-cell>
    <van-cell title="退款状态" v-if="order.refundStatus === 5">退款完成</van-cell>
    <van-cell title="可退款时间" v-if="order.refundStatus === 4">{{formatDate(refund.updateTime + 1000 * 60 * 60 * 24 * config.refundTime)}}后</van-cell>
    <van-cell title="退款时间" v-if="order.refundStatus === 5">{{formatDate(refund.configTime)}}</van-cell>
    <van-field
        v-if="this.order.refundStatus === 2"
        v-model="form.refundMsg"
        type="textarea"
        label="退款原因"
        placeholder="请输入退款原因"
        rows="4"
        maxlength="100"
        show-word-limit
    />
    <div style="margin: 16px;">
      <van-button v-if="this.order.refundStatus === 2" round block type="info" @click="onSubmit">提交退款申请</van-button>
      <van-button  v-if="order.refundStatus === 4" round block type="info" @click="refundConfig">确认退款</van-button>
    </div>
  </div>
</template>
<script>
import FromTitle from '@/components/from-title';
import {refund,selectOne} from "@/api/refund";
import {selectOne as selectOrder,refundApply} from "@/api/order";
import {selectOne as selectConfig} from "@/api/config";
export default{
  components: {FromTitle},
  data(){
    return{
      form:{
        refundMsg:null,
      },
      refund: {},
      order:{},
      config:{
        refundTime:15
      }
    }
  },
  mounted() {
    this.form.orderNo = this.$route.query.orderNo;
    //查询当前订单
    selectOrder({id:this.$route.query.id}).then((res)=>{
      this.order = res.data
    })
    //查询当前订单的退款
    selectOne({orderNo:this.form.orderNo}).then((res)=>{
      if(res.code === 200){
        this.refund = res.data
      }
    })
    selectConfig().then((res)=>{
      if(res.code === 200){
        this.config = res.data;
      }
    })
  },
  methods: {
    refundConfig(){
      refund({orderNo:this.order.no}).then((res)=>{
        if (res.code === 200) {
          this.$toast('提交成功,资金将原路返回,请注意查收');
          this.$router.push('/order')
        }
      })
    },
    onSubmit() {
      if (!this.form.refundMsg) {
        this.$toast('请输入退款说明');
        return;
      }
      this.form.id = this.order.id;
      refundApply(this.form).then((res)=>{
        if (res.code === 200) {
          this.$toast('提交成功');
          this.$router.push('/order')
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.order-container{
  margin: 12px;
  padding-bottom: 64px;
  .order-info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px;

    .name{
      margin-top: 12px;
    }
  }
  .van-step--horizontal .van-step__circle-container, .van-steps{
    background: #fff;
  }
  .van-step--finish{
   color: #969799;
  }
}
</style>