import request from '@/utils/request.js'

export function create(data) {
    return request({
        url: '/api/weixin-pay/create',
        method: 'post',
        data
    })
}

export function pay(data) {
    return request({
        url: '/api/weixin-pay/pay',
        method: 'post',
        data
    })
}

export function alipay(data) {
    return request({
        url: '/api/ali-pay/pay',
        method: 'post',
        data
    })
}

export function getAppid() {
    return request({
        url: '/api/weixin-pay/getAppid',
        method: 'post'
    })
}

export async function getCode(orderNo) {
    let res = await getAppid();
    let data = {};
    let appid = res.data
    let url = window.location.href.split("#")[0];
    let redirect_uri = encodeURIComponent( url+ '#/weixin-pay');
    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_base&state=${orderNo}#wechat_redirect`
}
export async function wxLogin() {
    let res = await getAppid();
    let data = {};
    let appid = res.data
    let url = window.location.href.split("#")[0];
    let redirect_uri = encodeURIComponent( url+ '#/weixin-login');
    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATUS#wechat_redirect`
}