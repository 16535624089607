<template>
	<div class="from-title">
    <div class="title">{{title}}</div>
    <div class="subtitle">{{subtitle}}</div>
	</div>
</template>

<script>
	export default {
    props:['title','subtitle']
	};
</script>

<style lang="scss">
.from-title{
  display: flex;
  align-items: center;
  height: 40px;
  .title{
    border-left: 2px #007aff solid;
    padding:0 6px;
    line-height: 70%;
    color: #333;
    font-weight: bold;
    font-size: .9rem;
  }
  .subtitle{
    color: #999;
    font-size: 0.8rem;
  }
}
</style>
