<template>
	<div class="page">
		页面不存在
		<el-button style="margin-top: 12px;" @click="goHome">返回首页</el-button>
	</div>
</template>

<script>
	export default {
		data: function() {
			return {
			};
		},
		methods: {
			goHome(){
				localStorage.removeItem('cur_path_index');
				this.$router.push('/index')
			}
		}
	};
</script>

<style>
	.page{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 12px;
	}
</style>
