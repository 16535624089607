import request from '@/utils/request.js'

export function selectCities() {
  return request({
    url: '/api/city/select',
    method: 'post',
  })
}
export function selectAll() {
  return request({
    url: '/api/city/selectAll',
    method: 'post',
  })
}