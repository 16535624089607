<template>
  <div class="result-main">
    <van-icon name="success" size="64" color="#007aff" class="result-icon"/>
    <div class="tip">{{mainTip}}</div>
    <div class="tip">{{tip}}</div>
    <van-image :src="defaultImg?defaultImg:url" style="width: 70%"/>
    <div style="font-size: 12px;color: white;margin-top: 1rem;">{{otherTip}}</div>
    <div style="display: flex;justify-content: center;align-items: center;padding: 12px" v-if="showSoftBtn">
      <to-soft :no="form.orderNo" :softBtnText="softBtnText"/>
    </div>
  </div>
</template>

<script>
import ToSoft from "./toSoft.vue";
export default {
  components: {ToSoft},
  props:['mainTip','tip','defaultImg','otherTip','softBtnText','showSoftBtn','form'],
  data: () => {
    return {
      url: require('@/assets/img/result.png'),
    }
  },
};
</script>

<style lang="scss">
.result-main {
  background: #007aff;
  min-height: calc(100vh - 28px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 16px 12px 12px 12px;

  .result-icon {
    background: #23da23;
    border-radius: 50%;
  }

  .tip {
    font-size: 14px;
    color: white;
    width: 75%;
    text-align: center;
    margin: 12px 0;
  }
}
</style>