<template>
  <div>
    <van-field readonly v-model="form[attr]" label="车牌号码" placeholder="点击输入车牌号码" @click="openCarInput"/>
    <car-input ref="carInput" @input="inputCarNum"/>
  </div>
</template>

<script>
import CarInput from './carInput';

export default {
  components: {
    CarInput
  },
  props: ['form', 'attr','disabled'],
  methods: {
    openCarInput() {
      if (this.disabled) {
        return
      }
      this.$refs.carInput.show();
    },
    inputCarNum(carNum) {
      this.form[this.attr] = carNum;
      this.$forceUpdate()
    },
  }
};
</script>

<style lang="scss">
</style>
