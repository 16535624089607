<template>
  <div class="user-info-main">
    <van-steps :active="0" active-color="white" inactive-color="#e0e0e0">
      <van-step>收货信息</van-step>
      <van-step>定制设备</van-step>
      <van-step>物流配送</van-step>
    </van-steps>
    <div class="main">
      <from-title title="填写银行卡信息" subtitle="(必填)"/>
      <van-field v-model="form.name" label="持卡人姓名" placeholder="点击输入持卡人姓名"/>
      <van-field type="number" v-model="form.bankNum" label="银行卡号" placeholder="点击输入银行卡号"/>
      <van-field v-model="form.card" label="身份证号" placeholder="点击输入身份证号"/>
      <van-field type="number" v-model="form.phone" label="预留手机号" placeholder="点击输入银行预留手机号"/>
    </div>
    <div class="main">
      <from-title title="信用卡" subtitle="(信用卡必填)"/>
      <van-field v-model="form.time" label="有效期" placeholder="有效期(例如:2030-09)"/>
      <van-field v-model="form.code" label="安全码" placeholder="请填写安全码后三位"/>
    </div>
    <div class="main">
      <from-title title="签约验证码" subtitle="(先发送签约验证码)"/>
      <div style="display: flex;flex-wrap: nowrap">
        <van-field v-model="verifyCode1" label="验证码" placeholder="请填写验证码"/>
        <van-button style="white-space: nowrap" @click="send">{{ codeText }}</van-button>
      </div>
    </div>
    <div class="main">
      <from-title title="订单验证码" subtitle="(再发送订单验证码)"/>
      <div style="display: flex;flex-wrap: nowrap">
        <van-field v-model="verifyCode2" label="验证码" placeholder="请填写验证码"/>
        <van-button style="white-space: nowrap" @click="sendOrder">{{ codeText }}</van-button>
      </div>
    </div>
    <div class="btn mt16" @click="next">下一步</div>
  </div>
</template>

<script>
import FromTitle from '@/components/from-title';
import {sd, sign, submit} from "../api/bank";

export default {
  components: {FromTitle},
  data: () => {
    return {
      form: {
        name: null,
        phone: null,
        card: null,
        bankNum: null,
        time: null,
        code: null,
        objId: null,
        verifyCode: null,

      },
      verifyCode1:null,
      verifyCode2:null,
      order: null,
      codeText: "发送验证码",
      sdResult:null
    }
  },
  mounted() {
    this.validate();
  },
  methods: {
    send() {
      //保存用户信息
      if (!this.form.name) {
        this.$toast('请输入持卡人姓名');
        return;
      }
      if (!this.form.phone) {
        this.$toast('请输入银行预留手机号');
        return;
      }
      if (!this.form.card) {
        this.$toast('请输入身份证号码');
        return;
      }
      if (!this.form.bankNum) {
        this.$toast('请输入银行卡号');
        return;
      }
      this.form.objId = this.order.id;
      sd(this.form).then(res => {
        this.sdResult = res.data;
        this.$toast("验证码发送成功!");
      })

    },
    sendOrder() {
      if (!this.sdResult.seq_no) {
        this.$toast('请先发送签约验证码');
        return;
      }
      if (!this.verifyCode1) {
        this.$toast('请输入签约验证码');
        return;
      }
      this.sdResult.verifyCode = this.verifyCode1;
      sign(this.sdResult).then(res => {
        //跳转到下一步
        this.sdResult = res.data;
        this.$toast("验证码发送成功!");
      })
    },
    next(){
      if (!this.sdResult.seq_no) {
        this.$toast('请发送订单验证码');
        return;
      }
      if (!this.verifyCode2) {
        this.$toast('请输入订单验证码');
        return;
      }
      this.sdResult.verifyCode = this.verifyCode2;
      submit(this.sdResult).then(res => {
        //跳转到下一步
        this.$router.push('/result')
      })
    }
  }
};
</script>

<style lang="scss">
.user-info-main {
  background: #007aff;
  min-height: 100vh;

  .main {
    border-radius: 6px;
    background: #FFFFFF;
    padding: 12px;
    margin: 12px;
  }
}

.van-radio {
  padding: 10px 16px;
}

.agree-box {
  font-size: 12px;
  vertical-align: middle;
  font-weight: 400;
  font-stretch: normal;
  letter-spacing: 1px;
  color: #000;
  line-height: 23px;
}

.xy {
  font-size: 11px;
  font-weight: 400;
  font-stretch: normal;
  letter-spacing: 1px;
  color: #0e77ff;
  z-index: 1;
  line-height: 18px;
}

.mt16 {
  margin-top: 16px;
}
</style>
