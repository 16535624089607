<template>
  <div class="complain-container">
    <from-title title="投诉"/>
    <div v-if="complaint && complaint.id">
      <van-steps direction="vertical"
                 :active="complaint.replies.length-1"
                 inactive-color="#333"
                 active-color="#1989fa">
        <van-step v-for="item in complaint.replies">
          {{item.name}}：{{item.content}}
        </van-step>
      </van-steps>
    </div>
    <van-field
        readonly
        clickable
        label="投诉问题"
        :value="form.description"
        placeholder="请选择投诉问题"
        @click="showPicker = true"
        v-else
    />
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker
          show-toolbar
          :columns="columns"
          @cancel="showPicker = false"
          @confirm="onConfirm"
      />
    </van-popup>
    <van-field
        v-model="form.content"
        type="textarea"
        :label="complaint && complaint.id?'投诉回复':'投诉内容'"
        placeholder="请输入投诉内容"
        rows="4"
        maxlength="100"
        show-word-limit
    />
    <div style="margin: 16px;">
      <van-button round block type="info" @click="onSubmit">提交</van-button>
    </div>
  </div>
</template>
<script>
import FromTitle from '@/components/from-title';
import {add,selectOne,reply} from "@/api/complaint";
export default{
  components: {FromTitle},
  data(){
    return{
      form:{
        description:null,
        content:null,
        orderNo:null
      },
      columns: ['支付成功商家未收到钱', '账户被莫名扣费', '不满意商家的商品或服务', '交易被骗', '其他问题'],
      showPicker:false,
      complaint: {}
    }
  },
  mounted() {
    this.form.orderNo = this.$route.query.orderNo;
    //查询当前订单的投诉
    selectOne({orderNo:this.form.orderNo}).then((res)=>{
      if(res.code === 200){
        this.complaint = res.data
      }
    })
  },
  methods: {
    onConfirm(value) {
      this.form.description = value;
      this.showPicker = false;
    },
    onSubmit() {
      if(this.complaint && this.complaint.id){
        if (!this.form.content) {
          this.$toast('请输入投诉回复');
          return;
        }
        this.form.complaintId = this.complaint.id
        reply(this.form).then((res)=>{
          if (res.code === 200) {
            this.$toast('提交成功');
            this.$router.push('/order')
          }
        })
        return;
      }

      if (!this.form.description) {
        this.$toast('请选择投诉问题');
        return;
      }
      if (!this.form.content) {
        this.$toast('请输入投诉内容');
        return;
      }
      add(this.form).then((res)=>{
        if (res.code === 200) {
          this.$toast('提交成功');
          this.$router.push('/order')
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.complain-container{
  margin: 12px;

  .van-step--horizontal .van-step__circle-container, .van-steps{
    background: #fff;
  }
  .van-step--finish{
   color: #969799;
  }
}
</style>