import request from '@/utils/request.js'

export function sign(data) {
  return request({
    url: '/api/bank/sign',
    method: 'post',
    data
  })
}
export function sd(data) {
  return request({
    url: '/api/bank/sd',
    method: 'post',
    data
  })
}
export function submit(data) {
  return request({
    url: '/api/bank/submit',
    method: 'post',
    data
  })
}