<template>
  <div>
    <build v-for="(item,index) in rules" :key="index" :item="item"
         :rule="item"
         v-if="pageShow(item)"
         :form="form"
         @next-step="nextStep(item)"
         @pay="pay(item)"
    />
    <pay ref="pay"/>
  </div>
</template>

<script>
import Build from "@/components/custom/build";
import pay from "@/components/pay";
import * as api from "@/api/pro-rule-page";
import {add,selectOne as getOrder,edit,selectAllByUserInfo} from '@/api/order'
import { Dialog } from 'vant';
export default {
  components: {
    Build, pay
  },
  data() {
    return {
      form: {},
      page: 1,
      rules: [],
      timer: null,
      preRule: null,
      proId:-1,
      srcHeight:false,
      keyboardFlag:false,
      curPageRule:{page:1,rule:null}
    }
  },
  mounted() {
    let query = this.$route.query;
    this.form.inUrl = query.inUrl;
    this.form.type = query.type;

    if(!this.form.id){
      let form = JSON.parse(localStorage.getItem("tempForm"))
      this.form = form ? form : this.form;
    }

    //查找当前的页面
    api.selectAll({type: query.type}).then(res => {
      this.rules = res.data;
      // this.form.proId = res.data[0].proId;
      this.proId = res.data[0].proId;
      let pageStr = localStorage.getItem("tempPage")
      if(pageStr){
        this.page = parseInt(pageStr);
      }
    })
    clearInterval(this.timer);
    //轮询订单是否支付成功
    this.timer = setInterval(() => {
      let orderId = localStorage.getItem("orderId");
      if(!orderId)return;
      if(!this.form.id){
        let form = JSON.parse(localStorage.getItem("tempForm"))
        this.form = form ? form : this.form;
      }
      //判断用户是否点击了支付方法 否则不去后台获取订单 也不覆盖form
      let payType = localStorage.getItem("payType")
      if(!payType)return;
      getOrder({id: orderId}).then(res => {
        //支付成功
        if (res.data.status === 2) {
          clearInterval(this.timer);
          localStorage.removeItem("orderId");
          localStorage.removeItem("tempForm");
          this.page = parseInt(localStorage.getItem("tempPage")) + 1;
          localStorage.removeItem("tempPage")
          localStorage.removeItem("payType")
          this.form.orderNo = res.data.no
          //记录选择的商品ID 为检测规则准备
          this.proId = res.data.proId;
          //支付后也要检测规则
          this.checkRules();
        }
      })
    }, 3000);
    let that = this;
    that.srcHeight = window.innerHeight
    window.addEventListener('resize', function() {
      that.keyboardFlag = that.srcHeight !== window.innerHeight;
    });
  },
  methods: {
    pageShow(rule){
      return rule.page === this.page;
    },
    realPay(){
      //先创建订单
      let order = this.getOrder();
      add(order).then(res=>{
        order.id = res.data.id;
        //将订单ID存到缓存
        localStorage.setItem("orderId",order.id+"");
        this.form['id'] = order.id;
        localStorage.setItem("tempForm",JSON.stringify(this.form));
        localStorage.setItem("tempPage",this.page+"");
        this.$refs.pay.open(res.data);
      })
    },
    pay(rule) {
      let elements = JSON.parse(rule.content);
      if (!this.validate(elements) || !this.patternValidate())return
      this.proId = this.form['proId'];
      //同产品下 判断如果车牌号和手机号存在 就查是否存在已支付的订单
      if(this.form.carNumber && this.form.phone){
        selectAllByUserInfo({carNumber: this.form.carNumber,phone: this.form.phone}).then(res=>{
          if(res.code === 200){
            let hasPay = false;
            for(let order of res.data){
              if(order.status > 1 && order.product.type === parseInt(this.form.type)){
                hasPay = true;
                break
              }
            }
            if(hasPay){
              Dialog.confirm({
                title:"提示",
                message: '您当前有已支付订单，请选择',
                confirmButtonText: '前往查看',
                cancelButtonText: '继续申请',
              }).then(() => {
                localStorage.setItem("user",JSON.stringify({carNumber: this.form.carNumber, phone: this.form.phone}));
                this.$router.push('/order')
                return
              }).catch(() => {
                this.realPay()
              });
            }else {
              this.realPay()
            }
          }
        })
        return
      }
      this.realPay()
    },
    getOrder(){
      return {
        id:this.form['id'],
        type:this.form['type'],
        proId:this.form['proId'],
        inUrl:this.form['inUrl'],
        contacts:this.form['contacts'],
        bank:{
          name:this.form['bank_name'],
          phone:this.form['bank_phone'],
          bankNum:this.form['bank_num'],
          card:this.form['bank_card'],
          code:this.form['bank_code']
        },
        attrReq:{
          url1:this.form['url1'],
          url2:this.form['url2'],
          url3:this.form['url3'],
          url4:this.form['url4'],
          url5:this.form['url5']
        },
        userInfo:{
          carNumber:this.form['carNumber'],
          carType:this.form['carType'],
          name:this.form['name'],
          phone:this.form['phone'],
          province:this.form['province'],
          city:this.form['city'],
          area:this.form['area'],
          address:this.form['address'],
          card:this.form['card']
        }
      }
    },
    nextStep(rule) {
      let elements = JSON.parse(rule.content);
      if (this.validate(elements) && this.patternValidate()) {
        this.proId = this.form['proId'];
        if(this.form['id']){
          //修改订单
          edit(this.getOrder()).then(res=>{
            if (res.code === 200){
              this.page++;
            }else{
              this.$toast({
                message: res.msg
              })
            }
          })
          return
        }
        this.page++;
        //下一步需要检测规则
        this.checkRules();
      }
    },
    checkRules(){
      window.scrollTo(0,0);
      //当前页码的页面数大于1
      if(this.rules.filter(item=>item.page === this.page).length > 1){
        //需要保留的
        let reserveRules = this.rules.filter(item=>item.page === this.page && (item.proId === this.proId || item.proId === -1));
        //如果有两个 就删除为-1的
        if(reserveRules.length > 1){
          let delRules = this.rules.filter(item=>item.page === this.page && item.proId === -1);
          for (let delRule of delRules) {
            this.rules.splice(this.rules.indexOf(delRule), 1);
          }
          //如果只有一个 就删除不为这个rule id的
        }else if(reserveRules.length === 1){
          let delRule = this.rules.filter(item=>item.page === this.page && item.id !== reserveRules[0].id);
          this.rules.splice(this.rules.indexOf(delRule[0]), 1);
        }
      }
    },
    //非空校验
    validate(elements) {
      //将数组展开平放
      elements = this.arrExpand(elements, []);
      for (let ele of elements) {
        //不是非空 有组件与产品绑定的 并且输入正确了
        if(ele.err && ele.attr && ele.proId && ele.proId !== this.form.proId && !this.form[ele.attr]){
          continue;
        }
        //有绑定属性和错误提示 && 没有输入
        if (ele.err && ele.attr && (!this.form[ele.attr] || this.form[ele.attr] === -1)) {
          if(this.form['jump'] && (ele.attr === 'url1' || ele.attr === 'url2' || ele.attr === 'url3' || ele.attr === 'url4' || ele.attr === 'url5')){
            continue;
          }
          this.$toast({
            message: ele.err,
          })
          return false
        }
      }
      return true
    },
    arrExpand(srcArr, targetArr) {
      for (let ele of srcArr) {
        if (ele.children && ele.children.length) {
          this.arrExpand(ele.children, targetArr);
        }
        targetArr.push(ele);
      }
      return targetArr;
    },
    //格式校验
    patternValidate(){
      if(this.form['phone'] && !/^1[3456789]\d{9}$/.test(this.form['phone'])) {
        this.$toast({message: '手机号格式不正确'})
        return false;
      }
      if(this.form['bank_phone'] && !/^1[3456789]\d{9}$/.test(this.form['bank_phone'])) {
        this.$toast({message: '手机号格式不正确'})
        return false;
      }
      // //只验证是数字 不验证银行卡
      // if(this.form['bank_num'] && !/^[0-9]$/.test(this.form['bank_num'])) {
      //   this.$toast({message: '银行卡号格式不正确'})
      //   return false;
      // }
      if(this.form['contacts'] && this.form['contacts'].length < 2) {
        this.$toast({message: '请完善联系人信息'})
        return false;
      }
      //姓名验证
      if(this.form['contacts'] && !this.form['contacts'][0]['name']) {
        this.$toast({message: '请填写联系人1姓名'})
        return false;
      }
      if(this.form['contacts'] && !this.form['contacts'][1]['name']) {
        this.$toast({message: '请填写联系人2姓名'})
        return false;
      }
      //关系验证
      if(this.form['contacts'] && !this.form['contacts'][0]['relation']) {
        this.$toast({message: '请选择联系人1关系'})
        return false;
      }
      if(this.form['contacts'] && !this.form['contacts'][1]['relation']) {
        this.$toast({message: '请选择联系人2关系'})
        return false;
      }
      //手机号码验证
      if(this.form['contacts'] && (!this.form['contacts'][0]['phone'] || !/^1[3456789]\d{9}$/.test(this.form['contacts'][0]['phone']))) {
        this.$toast({message: '联系人1手机号格式不正确'})
        return false;
      }
      if(this.form['contacts'] && (!this.form['contacts'][1]['phone'] || !/^1[3456789]\d{9}$/.test(this.form['contacts'][1]['phone']))) {
        this.$toast({message: '联系人2手机号格式不正确'})
        return false;
      }
      if(this.form['contacts'] && this.form['contacts'][0]['phone'] === this.form['contacts'][1]['phone']){
        this.$toast({message: '联系人手机号码不能一致'})
        return false;
      }
      if(this.form['card'] && !/^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(this.form['card'])) {
        this.$toast({message: '身份证格式不正确'})
        return false;
      }
      if(this.form['bank_card'] && !/^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(this.form['bank_card'])) {
        this.$toast({message: '身份证格式不正确'})
        return false;
      }
      if(this.form['carNumber'] && this.form['carType']){
        switch (parseInt(this.form['carType'])) {
          case 1://验证蓝色车牌号
            if(!/^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z0-9]{6}$/.test(this.form['carNumber'])) {
              this.$toast({message: '车牌号格式不正确'});
              return false;
            }
            break;
          case 2://验证绿色车牌号
            if(!/^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z0-9]{7}$/.test(this.form['carNumber'])) {
              this.$toast({message: '车牌号格式不正确'});
              return false;
            }
            break;
          case 3://验证黄色车牌号
            if(!/^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z0-9]{6}$/.test(this.form['carNumber'])) {
              this.$toast({message: '车牌号格式不正确'});
              return false;
            }
            break;
        }
      }
      return true;
    }
  }
};
</script>

<style lang="scss">
</style>
