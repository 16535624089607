<template>
  <div>
    <van-overlay :show="show" class="mark-box">
      <div class="mark-main">
        <van-image class="img1" :src="img1"/>
        <div class="tip1">
          <div>根据交通运输部规定</div>
          <div>办理ETC需以下资料</div>
        </div>
        <van-image class="img2" :src="img2"/>
        <div class="tip2">
          <span>身份证正反面</span>
          <span>车头照(带车牌号)</span>
          <span>行驶证正副本</span>
        </div>
        <div class="btn3" @click="show = false">我知道了</div>
        <div class="tip3">申办资料仅用于ETC设备与车辆数据绑定！</div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: true,
      img1: require("@/assets/img/1.jpg"),
      img2: require("@/assets/img/2.png"),
    }
  }
};
</script>

<style lang="scss">
.mark-box {
  display: flex;
  justify-content: center;
  align-items: center;

  .mark-main {
    width: calc(100vw - 4rem);
    border-radius: 8px;
    overflow: hidden;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .tip1 {
      font-size: 21px;
      font-family: PingFang SC;
      font-weight: 700;
      color: #333;
      margin: 12px 0;
    }

    .img2 {
      margin: 0 12px;
    }

    .tip2 {
      width: calc(100% - 24px);
      margin: 0 12px;
      display: flex;
      justify-content: space-between;
      font-size: 10px;
      font-family: PingFang SC;
      font-weight: 300;
      color: #666;
    }

    .btn3 {
      width: 217px;
      height: 43px;
      border-radius: 6px;
      line-height: 43px;
      text-align: center;
      background: #1677ff;
      color: #fff;
      margin: 12px 0;
    }

    .tip3 {
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #999;
      margin-bottom: 12px;
    }
  }
}
</style>
