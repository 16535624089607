<template>
  <div class="product-type-container">
    <div class="product-type-box">
      <div v-for="type in types" class="product-type-item" :class="'product-type-item-'+(type.type)"
           @click="open(type)">
        <div class="title">
          {{ type.title }}
          <div class="tag" v-if="type.tag">{{ type.tag }}</div>
        </div>
        <div class="subtitle">{{ type.subtitle }}</div>
      </div>
    </div>
    <div class="product-tips">
      <div>
        <van-icon class="icon" name="success" color="#fff"/>
        信息&amp;支付安全
      </div>
      <div>
        <van-icon class="icon" name="success" color="#fff"/>
        官方授权
      </div>
      <div>
        <van-icon class="icon" name="success" color="#fff"/>
        办理便捷
      </div>
    </div>
  </div>
</template>
<script>
import {selectAll} from '@/api/product-type'

export default {
  data: () => {
    return {
      types: [],
    }
  },
  created() {
    selectAll({}).then(res => {
      this.types = res.data;
      //根据type排序
      this.types.sort((a, b) => {
        return a.type - b.type;
      })
    });
  },
  methods: {
    open(type) {
      this.$emit('open',type.type);
    },
  }
}
</script>

<style lang="scss">
.product-type-container {
  margin: 0 12px 12px 12px;
  padding: 14px;
  background: #FFFFFF;
  border-radius: 6px;
  box-sizing: border-box;

  .product-type-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .product-type-item {
    width: calc(47% - 2rem);
    margin-bottom: 12px;
    padding: 0.5rem 1rem 0.5rem 1rem;
    border-radius: 0.5405rem;

    .title {
      display: flex;
      font-size: 1.1rem;
      font-weight: bold;
      white-space:nowrap;

      .tag {
        width: 1.8919rem;
        height: 0.9459rem;
        text-align: center;
        line-height: 0.9459rem;
        font-size: 0.5946rem;
        border-radius: 10px 10px 10px 0px;
        margin-left: 0.3703rem;
        white-space:nowrap;
      }
    }

    .subtitle {
      margin-top: 0.2703rem;
      font-weight: bold;
      font-size: 0.5946rem;
      white-space:nowrap;
    }

    &-1 {
      background: #3E6EDC;
      color: #FFFFFF;

      .title {
        color: #F5F5F5;

        .tag {
          background-color: #F5F5F5;
          color: #3E6EDC;
          white-space:nowrap;
        }
      }

      .subtitle {
        color: #F5F5F5;
      }
    }

    &-2 {
      background: #FDB423;
      color: #FFFFFF;

      .title {
        color: #F5F5F5;

        .tag {
          background-color: #3E6EDC;
          color: #F5F5F5;
        }
      }

      .subtitle {
        color: #F5F5F5;
      }
    }

    &-3 {
      background: #534938;
      color: #FFFFFF;

      .title {
        color: #F5F5F5;

        .tag {
          background-color: #F5F5F5;
          color: #3E6EDC;
        }
      }

      .subtitle {
        color: #b6b3b3;
      }
    }

    &-4 {
      background: #333;
      color: #FFFFFF;

      .title {
        color: #F5F5F5;

        .tag {
          background-color: #F5F5F5;
          color: #3E6EDC;
        }
      }

      .subtitle {
        color: #b6b3b3;
      }
    }

    &:nth-child(n+3) {
      margin-bottom: 0;
    }
  }

  .product-tips {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;

    .icon {
      border-radius: 50%;
      background: #3E6EDC;
      color: #CBCBCB;
      font-size: 12px;
      margin-left: 0.2703rem;
      line-height: 0.8649rem;
    }
  }
}
</style>