<template>
	<div v-if="show">
    <van-field
        readonly
        clickable
        label="选择地区"
        :value="curCity"
        placeholder="请选择收货地址地区"
        @click="showPicker = true"
    />
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker
          show-toolbar
          :columns="cities"
          value-key="name"
          @cancel="showPicker = false"
          @confirm="onConfirm"
      />
    </van-popup>
	</div>
</template>

<script>
import {selectCities} from "@/api/city";
	export default {
    props:['form','attr','proId'],
    data:()=>{
      return {
        cities: [],
        curCity: null,
        showPicker: false,
        show:false,
        timer:null,
      }
    },
    mounted() {
      //获取省市区级联
      selectCities().then(res => {
        this.cities = res.data;
      })
      clearInterval(this.timer)
      this.update();
      this.timer = setInterval(()=>{
        this.update();
      },200)
    },
    methods:{
      onConfirm(cityValues, indexes) {
        let attrs = this.attr.split(",");
        this.curCity = cityValues[0] + " " + cityValues[1] + " " + cityValues[2];
        this.showPicker = false;
        this.form[attrs[0]] = this.cities[indexes[0]].id;
        this.form[attrs[1]] = this.cities[indexes[0]].children[indexes[1]].id;
        this.form[attrs[2]] = this.cities[indexes[0]].children[indexes[1]].children[indexes[2]].id;
        this.form[this.attr] = this.curCity;
      },
      update(){
        //组件需要值 但是表单中没有值或是不需要的值 就不显示
        if(this.proId && (!this.form.proId || this.form.proId === -1 || this.proId !== this.form.proId)){
          this.show = false;
          return
        }
        this.show = true;
      }
    }
	};
</script>

<style lang="scss">
</style>
