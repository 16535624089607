<template>
  <div>
    <div class="product-item-box" :class="curIndex === index?'active-item':''" v-for="(product,index) in products"
         @click="selectProduct(index)">
      <div class="title">
        <div class="tt-lf">
          <van-image
              width="100"
              style="margin-right: 8px"
              :src="product.tag"
          />
          {{ product.name }}
        </div>
        <van-icon v-if="curIndex === index" name="success" size="16" color="white"
                  style="border-radius: 50%;background: #007aff;"/>
      </div>
      <div class="main">
        <van-image
            width="60"
            :src="product.img"
        />
        <div class="remark1">
          <ul>
            <li v-for="remark in JSON.parse(product.remark1)" style="margin-bottom: 6px">· {{ remark }}</li>
          </ul>
        </div>
        <div class="other">
          <div class="tip">{{ product.tip }}</div>
          <div class="realPrice">¥ {{ product.realPrice }}</div>
          <del class="price">{{ product.price }}</del>
        </div>
      </div>
      <van-divider
          @click.stop="openProp(product)"
          :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px',margin:'8px 0',fontSize:'12px' }">

        {{ product.btTitle }}
      </van-divider>
      <div v-if="curIndex === index" class="remark2">
        <ul>
          <li v-for="remark in JSON.parse(product.remark2)" style="margin-bottom: 6px">· {{ remark }}</li>
        </ul>
      </div>
    </div>
    <van-popup
        v-model="showProp"
        closeable
        close-icon="close"
        position="bottom"
        :style="{ height: '80%' }"
    >
      <p v-if="curProduct" v-html="curProduct.btContent" style="padding: 12px"></p>
    </van-popup>
  </div>
</template>

<script>
import {selectAll} from "@/api/product";

export default {
  props: ['type', 'form', 'attr'],
  data: () => {
    return {
      products: [],
      curIndex: -1,
      showProp: false,
      curProduct: null,
    }
  },
  mounted() {
    selectAll({status: 1, type: this.type}).then(res => {
      this.products = res.data;
    })
  },
  methods: {
    openProp(product) {
      this.curProduct = product;
      this.showProp = true;
    },
    selectProduct(index) {
      if (this.curIndex === index) {
        this.curIndex = -1;
      } else {
        this.curIndex = index;
        this.form[this.attr] = this.products[index].id;
      }
    },
  }
};
</script>

<style lang="scss">
.product-item-box {
  background: white;
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 12px;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 8px;


    .tt-lf {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: 700;
      color: #000;

    }
  }

  .main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 12px 12px;

    .remark1 {
      margin: 0 12px;
      color: rgb(255, 41, 77);
      line-height: 18px;
      font-size: 11px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      white-space: nowrap;
    }

    .other {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;

      .tip {
        white-space: nowrap;
        background: linear-gradient(270deg, rgb(255, 229, 229) 0%, rgb(255, 229, 229) 2%, rgba(255, 229, 229, 0) 100%);
        color: rgb(255, 34, 74);
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #ff224a;
        text-align: right;
        line-height: 18px;
      }

      .realPrice {
        font-size: 14px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: 700;
        color: #ff3b30;
        margin: 6px 0;
      }

      .price {
        font-size: 12px;
        font-family: PingFang SC-Medium, PingFang SC;
        color: #999;
      }
    }
  }

  .remark2 {
    padding: 0 12px 12px 12px;
    position: relative;
    line-height: 20px;
    font-size: 11px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #333;
  }
}
</style>
