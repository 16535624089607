import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/common.css';
import {
    Button, Row, Col, Tabbar, TabbarItem, NoticeBar, Swipe, SwipeItem,
    Lazyload, Tab, Tabs, Icon, Step, Steps, ActionSheet, Field, Cell, CellGroup,
    RadioGroup, Radio, Image as VanImage, Divider, Card, Overlay, Uploader, Toast, Picker,
    Popup, Checkbox,Panel
} from 'vant'
Vue.use(Button).use(Row).use(Col).use(Tabbar).use(TabbarItem).use(NoticeBar).use(Swipe).use(SwipeItem).use(Lazyload).use(Tab).use(Tabs).use(Icon).use(Step)
    .use(Steps).use(Step).use(ActionSheet).use(Field).use(Cell).use(CellGroup).use(Radio)
    .use(RadioGroup).use(VanImage).use(Divider).use(Card).use(Overlay).use(Uploader)
    .use(Toast).use(Picker).use(Popup).use(Checkbox).use(Panel);

Vue.config.productionTip = false
Vue.config.ignoredElements = ['wx-open-launch-weapp']

//验证订单
Vue.prototype.validate = function () {
    this.order = localStorage.getItem("order");
    if (!this.order) {
        this.$toast({
            message: '请先填写收货信息',
            duration: 1500,
            onClose() {
                router.push("/userInfo");
            }
        });
        return;
    }
    this.order = JSON.parse(this.order);
}

//业务方法结束

/**
 * 将中间的内容变成***
 */
Vue.prototype.hideCenter = (str, pre, end) => {
    return str.substring(0, pre) + "***" + str.substring(str.length - end);
}
//计算时间间隔
Vue.prototype.diffTime = (time) => {
    let dateTime = new Date(time);  // replace方法将 - 转为 /
    let dateNow = new Date();  // 获取当前时间
    let timeDiff = dateNow.getTime() - dateTime.getTime();  // 时间差的毫秒数
    let days = Math.floor(timeDiff / (24 * 3600 * 1000)); // 计算出天数
    let leavel1 = timeDiff % (24 * 3600 * 1000); // 计算天数后剩余的时间
    let hours = Math.floor(leavel1 / (3600 * 1000)); // 计算天数后剩余的小时数
    let leavel2 = timeDiff % (3600 * 1000); // 计算剩余小时后剩余的毫秒数
    let minutes = Math.floor(leavel2 / (60 * 1000)); // 计算剩余的分钟数
    if (days > 0) {
        return days + "天前";
    }
    if (hours > 0) {
        return hours + "小时前";
    }
    if (minutes > 0) {
        return minutes + "分钟前";
    }
    return "刚刚";
}
Vue.prototype.getQueryString = (name) => {
    let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    let r = window.location.search.substr(1).match(reg);
    if (r != null) {
        return unescape(r[2]);
    }
    return null;
}
Vue.prototype.formatStr = function (str, length) {
    return str && str.length > length ? str.substring(0, length) : str;
}
Vue.prototype.formatTime = function (dateTime) {
    var now = new Date(dateTime)
    var year = now.getFullYear(),
        month = now.getMonth() + 1,
        date = now.getDate(),
        hour = now.getHours(),
        minute = now.getMinutes(),
        second = now.getSeconds();
    return (hour < 10 ? "0" + hour : hour) + ":" + (minute < 10 ? "0" + minute : minute) + ":" + (second < 10 ? "0" + second : second);
}
//yyyy-MM-dd HH:mm:ss格式化
Vue.prototype.formatDate = function (dateTime) {
    var now = new Date(dateTime)
    var year = now.getFullYear(),
        month = now.getMonth() + 1,
        date = now.getDate(),
        hour = now.getHours(),
        minute = now.getMinutes(),
        second = now.getSeconds();
    return year + "-" + (month < 10 ? "0" + month : month) + "-" + (date < 10 ? "0" + date : date) + " " + (hour < 10 ? "0" + hour : hour) + ":" + (minute < 10 ? "0" + minute : minute) + ":" + (second < 10 ? "0" + second : second);
}
//用于table中列的格式化
Vue.prototype.rowFormatDate = function (row, col) {
    var now = new Date(row[col.property])
    var year = now.getFullYear(),
        month = now.getMonth() + 1,
        date = now.getDate(),
        hour = now.getHours(),
        minute = now.getMinutes(),
        second = now.getSeconds();
    return year + "-" + (month < 10 ? "0" + month : month) + "-" + (date < 10 ? "0" + date : date) + " " + (hour < 10 ? "0" + hour : hour) + ":" + (minute < 10 ? "0" + minute : minute) + ":" + (second < 10 ? "0" + second : second);
}
Vue.prototype.openDialog = function (item, openDialogFn) {
    this.dialogVisible = true;
    if (openDialogFn) {
        openDialogFn(item);
    }
    if (item) {
        this.form = {
            ...item
        };
    }
}
/**
 * 关闭弹窗
 * @param {Object} that
 */
Vue.prototype.closeDialog = function () {
    this.dialogVisible = false;
}
/**
 * 查询重置函数
 * @param {Object} that
 * @param {Object} api
 * @param {Object} resetFn
 */
Vue.prototype.resetQuery = function (resetFn) {
    this.page.page = 1;
    this.data = [];
    if (resetFn) {
        resetFn();
    }
    this.queryPage();
}
/**
 * 高级查询+分页
 * @param {Object} that
 * @param {Object} api
 */
Vue.prototype.queryPage = function () {
    this.api.paging(this.page).then(res => {
        if (res.code != 200) {
            return;
        }
        this.page = res.data;
        this.page.totle = res.data.total;
        this.data = res.data.data;
        this.page.data = null;
    })
}
/**
 * 翻页处理
 * @param {Object} that
 * @param {Object} page
 */
Vue.prototype.handlePage = function (page) {
    this.page.page = page;
    this.queryPage();
}
/**
 * api添加和修改
 * @param {Object} that    vue页面对象
 * @param {Object} api    api对象
 * @param {Function} fn 列表的查询方法
 */
Vue.prototype.save = function (fn) {
    if (this.form.id) {
        this.api.edit(this.form).then(res => {
            if (res.code == 200) {
                this.$message.success("保存成功!");
                if (fn) {
                    fn();
                } else {
                    this.queryPage();
                }
                this.closeDialog();
            }
        })
        return;
    }
    this.api.add(this.form).then(res => {
        if (res.code == 200) {
            this.$message.success("保存成功!");
            if (fn) {
                fn();
            } else {
                this.queryPage();
            }
            this.closeDialog();
        }
    })
}
Vue.prototype.back = function () {
    router.go(-1)
}


new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
