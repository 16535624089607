<template>
  <div class="tabs">
    <div class="title">
      <div class="tabs-tt-item" v-for="(tab,index) in tabs"
           :class="active == index?'active-item':''" @click="changeTab(index)">
        {{ tab.title }}
        <div class="subTT">{{ tab.subtitle }}</div>
      </div>
    </div>
    <div class="main" v-for="(tab,index) in tabs" :key="tab.id" v-if="active === index">
      <div class="main-item" v-for="item in tab.items" :key="item.id">
        <van-icon :name="item.icon" size="32" color="#007aff"/>
        <div class="main-item-rt">
          <div class="main-tt">{{item.title}}</div>
          <div class="main-subTT">{{item.subtitle}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {list} from '@/api/home-tabs'
export default {
  props: {},
  data: function () {
    return {
      tabs: [
        {title: "办理流程", subtitle: "Process"},
        {title: "办理条件", subtitle: "Condition"},
        {title: "注销流程", subtitle: "Cancellation"},
        {title: "常见问题", subtitle: "Problem"}
      ],
      active: 0,
    };
  },
  mounted() {
    list().then(res => {
      this.tabs = res.data;
    });
  },
  methods: {
    changeTab(index) {
      this.active = index;
    }
  }
};
</script>

<style lang="scss">
.tabs {
  margin: 0 12px 12px 12px;
  background: #FFFFFF;
  border-radius: 6px;

  .title {
    display: flex;

    .tabs-tt-item {
      width: 25%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #E9EEF4;
      font-size: 1.05rem;
      font-weight: bold;
      color: #666666;
      padding-top: 0.4703rem;

      .subTT {
        font-size: .885rem;
        padding: 6px;
      }
    }

    .active-item {
      background: #FFFFFF;
      color: #007aff;
    }
  }

  .main {
    margin: .8rem 1rem 1rem 1rem;

    .main-item {
      display: flex;
      align-items: center;
      padding: 6px 0;

      .main-item-rt {
        margin-left: 1rem;
      }
    }

    .main-tt {
      color: #333333;
      font-size: 1rem;
      font-weight: bold;
    }

    .main-subTT {
      color: #AAAAAA;
      font-size: .8rem;
      font-weight: bold;
      padding: 6px 0;
    }
  }
}

</style>