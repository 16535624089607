import request from '@/utils/request.js'

export function selectAll(data) {
  return request({
    url: '/api/product/selectAll',
    method: 'post',
    data
  })
}
export function selectOne(data) {
  return request({
    url: '/api/product/selectOne',
    method: 'post',
    data
  })
}