<template>
  <div class="home">
    <div style="height: 0.8405rem;"/>
    <van-swipe :autoplay="3000" class="swipe-container" indicator-color="white">
      <van-swipe-item v-for="(image, index) in images" :key="index">
        <img v-lazy="image"/>
      </van-swipe-item>
    </van-swipe>
    <!--滚动订单-->
    <notice/>
    <!--产品类型-->
    <product-type-box @open="goto"/>
    <tabs/>
    <div style="display: flex;justify-content: center;">
      <div class="btn" @click="goto(1)">立即办理</div>
    </div>
  </div>
</template>
<script>
import ProductTypeBox from "@/components/custom/ProductTypeBox";
import Notice from "@/components/home/Notice.vue";
import Tabs from "@/components/home/Tabs.vue";
export default {
  components: {ProductTypeBox, Notice, Tabs},
  data: function () {
    return {
      images: [
        require('@/assets/img/home.jpg')
      ],
      inUrl:location.href
    };
  },
  mounted() {
    localStorage.removeItem("orderId");
    localStorage.removeItem("tempForm");
    localStorage.removeItem("tempPage")
    localStorage.removeItem("payType")
  },
  methods: {
    goto(type) {
      this.$router.push({
        path: '/dy-view',
        query: {type: type,inUrl:this.inUrl}
      });
    },
  }
}
</script>
<style lang="scss">
.home {
  background: #007aff;
  min-height: 100vh;
  padding-bottom: 62px;
}
.swipe-container {
  img {
    width: 100%;
    height: 140px;
  }
}
.btn{
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(360deg, #3F4BFF 0%, #5777FE 100%);
  border-radius: 27px;
  font-size: 14px;
  color: #fff;
  width: 130px;
  height: 40px;
  -webkit-animation-name: scaleDraw;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 5s;
  box-shadow: 1px 1px 2px #FFFFFF;
}
</style>
