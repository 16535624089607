import request from '@/utils/request.js'
export function login(data) {
    return request({
        url: '/api/user/login',
        method: 'post',
        data
    })
}
export function send(data) {
    return request({
        url: '/api/sms/send_login',
        method: 'post',
        data
    })
}