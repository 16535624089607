<template xmlns="van">
  <div class="login-main">
    <van-swipe :autoplay="3000" class="swipe-container" indicator-color="white">
      <van-swipe-item>
        <img v-lazy="image"/>
      </van-swipe-item>
    </van-swipe>
    <div class="main">
      <from-title title="用户登录"/>
      <van-field v-model="form.phone" label="车牌号码" placeholder="请输入手机号码"/>
      <van-cell>
        <van-field slot="title" type="number" v-model="form.code" label="手机号码" placeholder="请输入手机验证码"/>
        <van-button style="border: none;outline: none;color:#646566;" @click="sendCode">{{btn}}</van-button>
      </van-cell>
      <div class="btn mt16" style="cursor: pointer" @click="login">登录</div>
    </div>
  </div>
</template>

<script>
import FromTitle from "@/components/from-title";
import {login,send} from "@/api/login";

export default {
  components: {
    FromTitle
  },
  data: () => {
    return {
      form: {
        phone: null,
        code: null,
        type: 1
      },
      image: require('@/assets/img/home.jpg'),
      btn:'发送验证码',
      time:60,
    }
  },
  mounted() {
  },
  methods: {
    login() {
      if(!this.form.phone){
        this.$toast({
          message: '请输入手机号码',
        })
        return
      }
      if(!this.form.code){
        this.$toast({
          message: '请输入验证码',
        })
        return
      }
      login(this.form).then(res => {
        if(res.code === 200){
          localStorage.setItem("v-token", res.data);
          this.$router.push({
            path: '/my',
          });
        }
      })
    },
    sendCode(){
      if(!this.form.phone){
        this.$toast({
          message: '请输入手机号码',
        })
        return
      }
      if(this.btn === '发送验证码'){
        this.realSend();
        send({target: this.form.phone,countryNo:"+86",type: this.form.type}).then(res => {
          if (res.code === 200) {
            this.$toast({
              message: '验证码发送成功',
            })
          }
        })
      }else{
        this.$toast({
          message: '验证码已发送,请稍后',
        })
      }
    },
    realSend(){
      this.btn = (this.time--) +"秒后可发送";
      if(this.time <= 0){
        this.btn = '发送验证码';
        this.time = 60;
        return;
      }
      setTimeout(()=>{
        this.realSend();
      },1000);
    }
  }
};
</script>

<style lang="scss">
.login-main {
  background: #007aff;
  min-height: 100vh;
  padding: 12px;

  .main {
    background: white;
    border-radius: 12px;
    padding: 12px;
    margin-top: 24px;
  }
}
</style>
