<template>
  <div class="my-container">
    <div class="info-box">
      <van-image :src="require('@/assets/tb04.png')" width="75px" height="75px"/>
      <div class="info">
        <span>手机号：{{ user && user.phone }}</span>
        <span class="car-num">车牌号：{{ user && user.carNumber }}</span>
      </div>
    </div>
    <van-cell-group style="width: 100%;padding: 0 12px">
      <van-cell icon="shopping-cart-o" title="ETC订单" is-link to="/order"/>
      <van-cell icon="friends-o" title="关于我们" is-link to="/content?type=1"/>
      <van-cell icon="location-o" title="联系我们" is-link to="content?type=2"/>
      <van-cell icon="gem-o" title="安装教程" is-link to="content?type=3"/>
      <van-cell icon="chat-o" arrow-direction="down">
        <template #title>
          <a :href="config.chatUrl">在线客服</a>
        </template>
      </van-cell>
    </van-cell-group>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      user: {},
      config:{
        chatUrl:''
      }
    };
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.config = JSON.parse(localStorage.getItem("config"))
    console.log(this.config)
  },
  methods: {
    goHome() {
      localStorage.removeItem('cur_path_index');
      this.$router.push('/index')
    }
  }
};
</script>

<style>
.info-box {
  background-image: url('@/assets/bg.png');
  width: 100%;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.info {
  color: white;
  font-weight: bold;
  font-size: 17px;
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  align-content: center;
  justify-content: center;
}

.car-num {
  font-size: 14px;
  color: #fffbe8;
}
</style>
