<template xmlns="element-ui">
  <van-checkbox v-model="form[attr]">资料没在身边,跳过上传</van-checkbox>
</template>
<script>
	export default {
    props:['form','attr']
	};
</script>

<style lang="scss">
.van-checkbox{
  font-size: 14px !important;
}
</style>
